import { defaultUserState, notificationState, userState } from "../state";
import {registerRequest} from '../actions'
import { useState } from "react";
import {useRecoilState} from 'recoil';
import Modal from "./Modal";
import { RegisterCredentials, User } from "../dtos";
import { stringifyDate } from "../tools";
import { useUser } from "../hooks";
import { LoadingSubmitButton } from "./common";

type MyDate = {
    yearSet: boolean,
    monthSet: boolean,
    daySet: boolean,
    date: Date
}

export default function RegisterPrompt(){
    const [user, setUser] = useUser();
    const [issues, setIssues] = useState<string[]>([]);
    const [notification, setNotification] = useRecoilState(notificationState);

    const [credentials, setCredentials] = useState<RegisterCredentials>({
        email: "", 
        password: "", 
        passwordVerification: "", 
        town: "",
        firstName: "",
        lastName: "",
        gender: "",
        birthDate: ""});

    const [birthDate, setBirthDate] = useState<MyDate>({yearSet: false, date: new Date(), monthSet: false, daySet: false});

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.classList.remove("bg-red-100");
        const newCredentials = {...credentials} as RegisterCredentials;
        newCredentials[e.target.name] = e.target.value;
        setCredentials(newCredentials);
    }

    function _registerRequest(){
        let messages = [];
        if (credentials.passwordVerification !== credentials.password){
            document.querySelector("#passwordVerificationR")?.classList.add("bg-red-100");
            messages.push("Hesla se neshodují.")
        }
        if (credentials.password.length < 8){
            document.querySelector("#passwordR")?.classList.add("bg-red-100");
            messages.push("Heslo musí mít alespoň 8 znaků.")
        }
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(credentials.email)){
            document.querySelector("#emailR")?.classList.add("bg-red-100");
            messages.push("Email není ve správném formátu.")
        }
        if (!birthDate.daySet || !birthDate.monthSet || !birthDate.yearSet || birthDate.date<new Date(1900,0,1) || birthDate.date>new Date(2020,0,1)){
            messages.push("Datum narození musí být v rozmezí 1.1.1900 až 1.1.2020.")
        }

        if (credentials.town == ""){
            document.querySelector("#town")?.classList.add("bg-red-100");
            messages.push("Vyplňte město.")
        }

        if (credentials.firstName == ""){
            document.querySelector("#firstName")?.classList.add("bg-red-100");
            messages.push("Vyplňte jméno.")
        }

        if (credentials.lastName == ""){
            document.querySelector("#lastName")?.classList.add("bg-red-100");
            messages.push("Vyplňte příjmení.")
        }

        if (credentials.gender == ""){
            document.querySelector("#gender")?.classList.add("bg-red-100");
            messages.push("Vyplňte pohlaví.")
        }

        setIssues(messages)
        if (messages.length == 0){
            const postedCredentials = {...credentials, birthDate: stringifyDate(birthDate.date)};
            registerRequest(postedCredentials, setUser, setIssues, setNotification);
        }
    }

    return (
        <Modal isOpen={user.state == "register"}>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Registrace</h3>
            <form method="post" target="" className="space-y-6">
                <div>
                    <label htmlFor="emailR" className="formLabel required">Email</label>
                    <input id="emailR" type="text" name='email' placeholder="Váš email" onChange={_onChange} value={credentials.email} className="formInput"/>
                </div>
                <div>
                    <label htmlFor="passwordR" className="formLabel required">Heslo</label>
                    <input id="passwordR" type="password" name='password' onChange={_onChange} value={credentials.password} placeholder="Heslo" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="passwordVerificationR" className="formLabel required">Heslo (znovu)</label>
                    <input id="passwordVerificationR" type="password" name='passwordVerification' onChange={_onChange} value={credentials.passwordVerification} placeholder="Heslo (znovu)" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="town" className="formLabel required">Město</label>
                    <input id="town" type="text" name='town' onChange={_onChange} value={credentials.town} placeholder="Město" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="firstName" className="formLabel required">Jméno</label>
                    <input id="firstName" type="text" name='firstName' onChange={_onChange} value={credentials.firstName} placeholder="Vaše jméno" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="lastName" className="formLabel required">Příjmení</label>
                    <input id="lastName" type="text" name='lastName' onChange={_onChange} value={credentials.lastName} placeholder="Vaše příjmení" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="birthDate" className="formLabel required">Datum narození:</label>
                    <DatePicker date={birthDate} onChange={date=>setBirthDate(date)}/>
                </div>
                <div className="flex justify-between font-medium text-sm formLabel">
                    <div className="formLabel required">Pohlaví:</div>
                    <div className="col form-check flex gap-1 items-center">
                        <input className="form-check-input" type="radio" name="gender" id="igenderMale" value="MALE" onChange={_onChange}/>
                        <label className="form-check-label" htmlFor="igenderMale">
                            Muž
                        </label>
                    </div>
                    <div className="col form-check flex gap-1 items-center">
                        <input className="form-check-input" type="radio" name="gender" id="igenderFemale" value="FEMALE" onChange={_onChange}/>
                        <label className="form-check-label" htmlFor="igenderFemale">
                            Žena
                        </label>
                    </div>
                    <div className="col form-check flex gap-1 items-center">
                        <input className="form-check-input" type="radio" name="gender" id="igenderOther" value="OTHER" onChange={_onChange}/>
                        <label className="form-check-label" htmlFor="igenderOther">
                            Jiné
                        </label>
                    </div>
                </div>
                <ul className={`bg-red-200 p-2 rounded-md text-red-600 ${issues.length==0?"hidden":""}`}>
                    {issues.map((issue,i)=>(<li key={i}>{issue}</li>))}
                </ul>

                <LoadingSubmitButton text="Registrovat se" onClick={e=>{e.preventDefault();_registerRequest()}}/>
                <div className="flex justify-start">
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        Již máte účet? 
                        <div onClick={e => {e.preventDefault();setUser({...defaultUserState, state: "login"})}} className="link">Přihlášení</div>
                    </div>
                </div>
            </form>
        </Modal>

        
    );
}

type DatePickerProps = {
    date: MyDate,
    onChange: (date: MyDate) => void
}
function DatePicker({date, onChange}: DatePickerProps){
    // const [date, setDate] = useState(new Date());

    // function _onChange(newDate: Date){
    //     setDate(newDate);
    //     props.onChange(newDate);
    // }
    
    function updateDay(e: React.ChangeEvent<HTMLInputElement>){
        const newDate = {...date, daySet:true, date: new Date(date.date)};
        if (!parseInt(e.target.value) || parseInt(e.target.value)>31 || parseInt(e.target.value)<1) newDate.daySet = false;
        else newDate.date.setDate(parseInt(e.target.value))
        if (e.target.value.length == 2) 
            (document.querySelector("#birthMonth") as HTMLInputElement).focus();
        onChange(newDate)
    }

    function updateMonth(e: React.ChangeEvent<HTMLInputElement>){
        const newDate = {...date, monthSet:true, date: new Date(date.date)};
        if (!parseInt(e.target.value) || parseInt(e.target.value)>12 || parseInt(e.target.value)<1) newDate.monthSet = false;
        else newDate.date.setMonth(parseInt(e.target.value)-1)
        if (e.target.value.length == 2) 
            (document.querySelector("#birthYear") as HTMLInputElement).focus();
        onChange(newDate)
    }

    function updateYear(e: React.ChangeEvent<HTMLInputElement>){
        const newDate = {...date, yearSet:true, date: new Date(date.date)};
        if (!parseInt(e.target.value)) newDate.yearSet = false;
        else newDate.date.setFullYear(parseInt(e.target.value))
        onChange(newDate)
    }

    return (
        <div className="flex justify-around formInput">
            <input className="dateSubField" id="birthDate" autoComplete="off" maxLength={2} pattern="[0-9]*" inputMode="numeric" placeholder="DD" type="text" onChange={updateDay}/>
            /
            <input className="dateSubField" id="birthMonth" autoComplete="off" maxLength={2} pattern="[0-9]*" inputMode="numeric" placeholder="MM" type="text" onChange={updateMonth}/>
            /
            <input className="dateSubField" id="birthYear" autoComplete="off" maxLength={4} pattern="[0-9]*" inputMode="numeric" placeholder="RRRR" type="text" onChange={updateYear}/>
        </div>
    );
}

/*




        <div className={(user.state == "register" ? "" : "hidden ") + "fixed top-0 left-0 z-50 w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full flex justify-center align-middle"}>
        <div className="relative w-full h-full max-w-md md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <FaTimes className="hover:cursor-pointer w-10 h-10 absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto dark:hover:bg-gray-800 dark:hover:text-white"/>
                <div className="px-6 py-6 lg:px-8">
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Přihlášení</h3>
                    <form className="space-y-6" action="#">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input id="email" type="text" name='email' placeholder="petr.novak@seznam.cz" onChange={_onChange} value={credentials.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"/>
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Heslo</label>
                            <input id="password" type="password" name='password' onChange={_onChange} value={credentials.password} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"/>
                        </div>
                        
                        <button onClick={() => loginRequest(credentials, setUser)} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Přihlásit se
                        </button>
                        <div className="flex justify-between">
                            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                Nemáte účet? 
                                <div onClick={e => {e.preventDefault();loginRequest(credentials, setUser)}} className="text-blue-700 hover:underline dark:text-blue-500">Vytvořit účet</div>
                            </div>
                            <a href="#" className="text-sm text-blue-700 hover:underline dark:text-blue-500">Zapomenuté heslo?</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div> 
*/