import {useRecoilState} from 'recoil';
import { availableShoppingsState, availableShopsState, defaultUserState, notificationState, recommendationsState } from "./state";
import Routing from "./routing/Routing";
import {BrowserRouter, Route, Routes, withRouter} from "react-router-dom";
import MyRoutes from "./routing/Routes";
import RegisterPrompt from './components/RegisterPrompt';
import LoginPrompt from './components/LoginPrompt';
import Navbar from './components/Navbar';
import ShoppingListPage from './pages/ShoppingListPage';
import { useEffect } from 'react';
import { getRecommendations, loadShops, loadUser, userDTOToUser } from "./actions";
import ParametersPage from './pages/ParametersPage';
import OptimizedPage from './pages/OptimizedPage';
import AvailableShoppingsPage from './pages/AvailableShoppingsPage';
import SearchPage from './pages/SearchPage';
import ResetPasswordPrompt from './components/ResetPasswordPrompt';
import { NotificationPopup } from './components/NotificationPopup';
import InsertEmailPrompt from './components/InsertEmailPrompt';
import ChangePasswordPrompt from './components/ChangePasswordPrompt';
import ShoppingPage from './pages/ShoppingPage';
import SettingsPage from './pages/SettingsPage';
import ShoppingHistoryPage from './pages/ShoppingHistoryPage';
import { Helmet } from 'react-helmet';
import { Role, SearchItemDTO } from "./dtos";
import { useUser } from './hooks';

export default function PageWrapper(){
    const [user,setUser] = useUser();
    const [shops, setShops] = useRecoilState(availableShopsState);
    const [notification, setNotification] = useRecoilState(notificationState);
    const [recommendations, setRecommendations] = useRecoilState(recommendationsState);

    const [availableShoppings, setAvailableShoppings] = useRecoilState(availableShoppingsState);

    function initDarkMode(){
        if (localStorage.theme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    function logoutCallback(){
        setTimeout(()=>setNotification({type: "WARNING", text: "Je nutné se znovu přihlásit",show:true}),100);
        setUser({...defaultUserState, state: "login"});
    }

    function updateUserCallback(e){
        userDTOToUser(e.detail, setUser, ()=>{})//TODO finish
    }

    useEffect(() => {
        loadShops(setShops, setNotification);
        loadUser(setUser, setNotification);
        initDarkMode();

        const params = new URLSearchParams(window.location.search);
        if (params.get("emailVerified")==='true') setTimeout(()=>setNotification({type: "SUCCESS", text: "Email úspěšně ověřen",show:true}),101);
        if (params.get("emailVerified")==='false') setTimeout(()=>setNotification({type: "ERROR", text: "Nesprávné nebo již použité údaje pro ověření emailu.",show:true}),101);

        document.addEventListener('logout', logoutCallback)
        document.addEventListener('updateUser', updateUserCallback)
        return ()=>{
            document.removeEventListener('logout', logoutCallback)
            document.removeEventListener('updateUser', updateUserCallback)
        }
    }, []);

    useEffect(()=>{
        if (user.state !== "logged" || user.role === Role.TO_VERIFY){
            setAvailableShoppings([]);
        }else if (recommendations.length === 0){
            getRecommendations(setRecommendations);
        }
    }, [user])

    return (
        <BrowserRouter history={Routing._history}>
            <Helmet>
                <title>Shupty</title>
            </Helmet>
            <Navbar />
            <LoginPrompt />
            <RegisterPrompt />
            <ChangePasswordPrompt />
            <InsertEmailPrompt/>
            <NotificationPopup/>
            <Routes>
                <Route path="/" element={<ShoppingListPage />}/>
                <Route path={MyRoutes.shoppingList.path} element={<ShoppingListPage />}/>
                <Route path={MyRoutes.parameters.path} element={<ParametersPage />}/>
                <Route path={MyRoutes.currentShopping.path} element={<OptimizedPage />}/>
                <Route path={MyRoutes.optimizedShoppings.path} element={<AvailableShoppingsPage />}/>
                <Route path={MyRoutes.productSearch.path} element={<SearchPage />}/>
                <Route path={MyRoutes.settings.path} element={<SettingsPage />}/>

                <Route path={MyRoutes.shoppings.path} element={<ShoppingHistoryPage />}/>
                <Route path={MyRoutes.shopping.path} element={<ShoppingPage />}/>
                <Route path={MyRoutes.resetPassword.path} element={<ResetPasswordPrompt />}/>
            </Routes>
        </BrowserRouter>
    );

    //<LoginPage />
    //BrowserRouter: basename="/page"
    

}