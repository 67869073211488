import { defaultUserState, displayedPopupState, notificationState, userState } from "../state";
import {loginRequest} from '../actions'
import { useState } from "react";
import {useRecoilState} from 'recoil';
import Modal from "./Modal";
import { LoginCredentials, User } from "../dtos";
import { useUser } from "../hooks";
import { Loading, LoadingSubmitButton } from "./common";



export default function LoginPrompt(){
    const [user, setUser] = useUser();
    const [issues, setIssues] = useState<string[]>([]);
    const [displayedPopup, setDisplayedPopup] = useRecoilState(displayedPopupState)
    const [notification, setNotification] = useRecoilState(notificationState);

    const [credentials, setCredentials] = useState<LoginCredentials>({email: "", password: ""});

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.classList.remove("bg-red-100");
        const newCredentials = {...credentials} as LoginCredentials;
        newCredentials[e.target.name] = e.target.value;
        setCredentials(newCredentials);
    }

    function _loginRequest(){
        let messages = [];
        if (credentials.password.length < 8){
            document.querySelector("#passwordL")?.classList.add("bg-red-100");
            messages.push("Heslo musí mít alespoň 8 znaků.")
        }
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(credentials.email)){
            document.querySelector("#emailL")?.classList.add("bg-red-100");
            messages.push("Email není ve správném formátu.")
        }
        setIssues(messages)
        if (messages.length == 0){
            loginRequest(credentials, setUser, setNotification);
        }
    }

    return (
        <Modal isOpen={user.state == "login"}>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Přihlášení</h3>
            <form method="post" target="" className="space-y-6">
                <div>
                    <label htmlFor="emailL" className="formLabel">Email</label>
                    <input id="emailL" type="text" name='email' placeholder="Váš email" onChange={_onChange} value={credentials.email} className="formInput"/>
                </div>
                <div>
                    <label htmlFor="passwordL" className="formLabel">Heslo</label>
                    <input id="passwordL" type="password" name='password' onChange={_onChange} value={credentials.password} placeholder="Vaše heslo" className="formInput"/>
                </div>

                <ul className={`bg-red-200 p-2 rounded-md text-red-600 ${issues.length==0?"hidden":""}`}>
                    {issues.map(issue=>(<li>{issue}</li>))}
                </ul>
                
                <LoadingSubmitButton text="Přihlásit se" onClick={e=>{e.preventDefault();_loginRequest()}}/>
                <div className="flex justify-between">
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        Nemáte účet? 
                        <div onClick={e => {e.preventDefault();setUser({...defaultUserState, state: "register"})}} className="link">Vytvořit účet</div>
                    </div>
                    <a href="#" className="link" onClick={e=>{e.preventDefault();setDisplayedPopup("insertEmail")}}>Zapomenuté heslo?</a>
                </div>
                <div className="flex justify-end">
                    <a href="#" className="link" onClick={e=>{e.preventDefault();setDisplayedPopup("verifyEmail")}}>Znovu odeslat ověřovací email</a>
                </div>
            </form>
        </Modal>
    );
}
