import {axios} from "./customAxios";

const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json"
}


export const RequestQueue = {
    _queue: [],
    _busy: false,

    _fireNextRequest(){
        if (this._queue.length < 1){
            this._busy = false;
            document.getElementById("queueLoading").classList.remove("opacity-100");
            document.getElementById("queueLoading").classList.add("opacity-0");
            document.querySelectorAll(".queueLoading").forEach(el=>el.classList.add("hidden"))
            return;
        }
        const next = this._queue.shift();
        this._fireRequest(next);
    },

    _fireRequest(request){
        axios(request.url, request.options)
            .then((response) => {//403 404 409 500
                if ((response.status === 403 || response.status === 401) && request.url !== '/rest/users/current'){
                    document.dispatchEvent(new CustomEvent("logout"));
                    console.log("logging out")
                }
                if (response.status >= 400){ //server responded - no retry
                    console.log(`APP ISSUE: Response status ${response.status}`);
                    console.log(response);
                    request.reject(response);
                }else{
                    request.resolve(response);
                }
                this._fireNextRequest();
            })
            .catch((e)=>{
                //this should be retried
                console.log(e);
                console.log(request)
                if (e.response?.status === 412){
                    //update all users data first
                    this._fireRequest({
                        url: "/rest/users/current",
                        options: {
                            method: "GET",
                            headers: headers,
                            credentials: 'include'
                        },
                        resolve: (response)=>{
                            document.dispatchEvent(new CustomEvent("updateUser", {detail:response.data}));
                            this._fireRequest(request);
                        }, 
                        reject: (response)=>{
                            request.reject(response);
                            //TODO: probably logout here
                            document.dispatchEvent(new CustomEvent("logout"));
                            this._fireNextRequest();
                        }
                    });
                    
                    return;
                }
                if ((e.response?.status === 403 || e.response?.status === 401) && request.url !== '/rest/users/current'){
                    document.dispatchEvent(new CustomEvent("logout"));
                    console.log("logging out")
                }
                if (!request.noRetries && e.code==="ERR_NETWORK"){
                    setTimeout(()=>this._fireRequest(request), 2000);
                }else{
                    request.reject(e.response);
                    this._fireNextRequest();
                }
            })
    },

    rawRequest(request) {
        return new Promise((resolve, reject) => {
            if (this._busy){
                this._queue.push({...request, resolve: resolve, reject: reject});
            }else{
                this._busy = true;
                document.getElementById("queueLoading").classList.remove("opacity-0");
                document.getElementById("queueLoading").classList.add("opacity-100");
                document.querySelectorAll(".queueLoading").forEach(el=>el.classList.remove("hidden"))
                this._fireRequest({...request, resolve: resolve, reject: reject});
            }
        });
    },

    getJson(url, params = {}){
        const options = {
            method: "GET",
            headers: headers,
            credentials: 'include'
        };
        return this.rawRequest({
            url: url, 
            options: options, 
            noRetries: params.noRetries ? params.noRetries : false,
        });
    },

    deleteRequest(url){
        const options = {
            method: "DELETE",
            headers: headers,
            credentials: 'include'
        };
        return this.rawRequest({url: url, options: options});
    },

    postRequest(url){
        const options = {
            method: "POST",
            headers: headers,
            credentials: 'include'
        };
        return this.rawRequest({url: url, options: options});
    },

    putRequest(url){
        const options = {
            method: "PUT",
            headers: headers,
            credentials: 'include'
        };
        return this.rawRequest({url: url, options: options});
    },

    postJson(url, bodyJson){
        const options = {
            method: "POST",
            headers: headers,
            credentials: 'include',
            data: JSON.stringify(bodyJson)
        };
        return this.rawRequest({url, options});
    },

    putJson(url, bodyJson){
        const options = {
            method: "PUT",
            headers: headers,
            credentials: 'include',
            data: JSON.stringify(bodyJson)
        };
        return this.rawRequest({url, options});
    },
 
    
};