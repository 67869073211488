export type ShopDTO = {
    id: number,
    name: string,
    memberships: MembershipDTO[]
}

export type MembershipDTO = {
    id: number,
    name: string
}






export interface UserDTO {
	email: string,
	firstName: string,
	lastName: string,
	maxShopCount: number,
	role: Role,
	gender: Gender,
	birthDate: string
	town: string,
	notes: ShoppingListItemDTO[],
	shoppingListItems: ShoppingListItemDTO[],
	shoppings: ShoppingDTO[],
    currentShopping: ShoppingDTO,
	memberships: number[],
	shops: number[],
	blacklistedProducts: number[]
}


export interface User extends UserDTO {
    state: "logged" | "login" | "register"
}


export type ShoppingDTO = {
	id: number | null,
    shopIds: number[],
    timestamp?: string,
    state: ShoppingState,
	shoppingItems: ShoppingItemDTO[],
	totalCost: number,
	extraCost: number,
    temporalId?: number,
    
    missingItems: number,
    notes: MissingItemDTO[],
    products: ShoppingItemDTO[],
    [key: string]: any
}

export type ProductShoppingItemDTO = {
	id?: number,
    type: "EXTRA"|"REQ",
	offerId: number,
    productId: number,
	amount: number,
	confirmed: boolean,

    cost: number,
    percentageSale: number,
    offerEnd?: string,

    name: string,
    brand: string,
    packageSizeUnit: Unit,
    packageSize: number,
    category: string,

    shopName: string,
    membershipName: string,

    originalCost: number,
    originalAmount: number,
}

export type MissingItemDTO = {
	id: number,
    type: "MISS"|"NOTE",
    name: string,
	confirmed: boolean,
	productId?: number,
	amount: number,
	amountUnit: Unit,

    originalAmount: number,
    originalAmountUnit: Unit,
}

export type ShoppingItemDTO = ProductShoppingItemDTO|MissingItemDTO;


export type ShoppingListItemDTO = {
	id: number | null,
    type: "N"|"P",
	amount: number,
	amountUnit: Unit,
	maxCost: number | null,
    state: ShoppingListItemState,
    lastBuyDate: string | null,
    previousPurchasesCount: number,

    category: string | null,
    productId: number | null,
    name: string,
    group: boolean,
    bestOffer: OfferDTO | null | undefined, //TODO is that okay?
    shopIds: number[],
    isRecommendation?: boolean,

    subproducts: SubproductDTO[],
    [key: string]: string |number|Unit|boolean|number[]|OfferDTO|SubproductDTO[]|null|undefined,
}


export type SearchItemDTO = {
    id: number | null,
    name: string,
    group: boolean,
    bestOffer?: OfferDTO,
    shopIds: number[],
    popularity: number,
    baseAmount: number,
	baseAmountUnit: Unit,
    category: string,
    subProductsCount: number,
}

export type SubproductDTO = {
	id: number,
    name: string,
	packageSize: number,
	packageSizeUnit: Unit,
    brand: string,
    bestOffer: OfferDTO,
    shopIds: number[],
}

export interface OfferMap { [key: string]: OfferDTO }

export type ProductDTO = {
	id: number,
    name: string,
    baseAmountUnit: Unit,
    baseAmount: number,
	
    type: ProductType,
    offersByShop: OfferMap,

    subproducts: SubproductDTO[],

    packageSize: number,
	packageSizeUnit: Unit,
    brand: string,
    category: string
}

export type ProductType = {
    CONCRETE: "P",
    GROUP: "G"
}

export type OfferDTO = {
	shopId: number,
	offerId: number,
	amount: number,
	cost: number,
    sale: boolean,
    offerEnd: string,
	percentageSale: number,
    packageSize?: number,
    packageSizeUnit?: Unit,
}

export enum Unit {
    G = "G",
    KG = "KG",
    ML = "ML",
    L = "L",
    PKG = "PKG",
    KS = "KS"
}

export enum ShoppingListItemState{
    ACT = "ACT",
    NACT = "NACT",
    HID = "HID",
    OS = "OS",
}


export enum Role{
    ADMIN = "ADMIN",
    USER = "USER",
    TO_VERIFY = "TO_VERIFY",
}

export enum Gender{
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER",
}

export enum ShoppingState{
    ONGOING = "ONGOING",
    FINISHED = "FINISHED",
    PARTIAL = "PARTIAL",
    ABORTED = "ABORTED",
    UNSOLVABLE = "UNSOLVABLE",
}


export type LoginCredentials = {
    email: string, 
    password: string,
    [key: string]: string
}

export type RegisterCredentials = {
    email: string, 
    password: string,
    passwordVerification: string, 
    town: string,
    firstName: string,
    lastName: string,
    gender: string,
    birthDate: string,
    [key: string]: string
}

export type StringMap = {
    [key: string]: string
}

export type ResetPasswordDTO = {
    email: string,
    password: string,
    passwordVerification: string,
    uuid: string,
    [key: string]: string
}

export type ChangePasswordDTO = {
    email: string,
    password: string,
    passwordVerification: string,
    oldPassword: string,
    [key: string]: string
}

export type NotificationType = "INFO" | "SUCCESS" | "WARNING" | "ERROR"

export type NotificationState = {
    text: string,
    type: NotificationType,
    show: boolean
}

export type ErrorResponseData = {
    message: string,
    requestUri: string
}

export type ErrorResponse = {
    status: number,
    data: ErrorResponseData
}