import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { changePassword, resetPassword } from "../actions";
import Modal from "./Modal";
import { ChangePasswordDTO, User } from "../dtos";
import { displayedPopupState, notificationState, userState } from "../state";
import { FaTimes } from "react-icons/fa";
import { useUser } from "../hooks";



export default function ChangePasswordPrompt(){
    const [issues, setIssues] = useState<string[]>([]);
    const [user, setUser] = useUser();
    const [credentials, setCredentials] = useState<ChangePasswordDTO>({password: "", passwordVerification: "", email: user.email, oldPassword: ""});
    const [notification, setNotification] = useRecoilState(notificationState);
    const [displayedPopup, setDisplayedPopup] = useRecoilState(displayedPopupState)

    useEffect(()=>{
        setCredentials({...credentials, email: user.email})
    }, [user.email])

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.classList.remove("bg-red-100");
        const newCredentials = {...credentials};
        newCredentials[e.target.name] = e.target.value;
        setCredentials(newCredentials);
    }
    

    function _onSubmit(){
        let messages = [];
        if (!credentials.email){
            messages.push("Chyba (email neuveden)")
        }
        if (!credentials.oldPassword){
            messages.push("Chyba - původní heslo neuvedeno")
        }
        if (credentials.password.length < 8 || !/\d/.test(credentials.password) || !/[a-zA-Z]/.test(credentials.password)){
            document.querySelector("#passwordL")?.classList.add("bg-red-100");
            messages.push("Heslo musí mít alespoň 8 znaků a musí obsahovat alespoň jedno číslo a alespoň jedno písmeno.")
        }
        if (credentials.passwordVerification !== credentials.password){
            document.querySelector("#passwordVerificationR")?.classList.add("bg-red-100");
            messages.push("Hesla se neshodují.")
        }
        setIssues(messages)
        if (messages.length == 0){
            changePassword(credentials, setUser, setNotification);
            setDisplayedPopup("");
        }
    }

    return (
        <Modal isOpen={displayedPopup==="changePassword"}>
            <FaTimes className="topRight icon" onClick={()=>setDisplayedPopup("")}/>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Přihlášení</h3>
            <form method="post" target="" className="space-y-6">
                <div>
                    <label htmlFor="oldPassword" className="formLabel">Původní heslo</label>
                    <input id="oldPassword" type="password" name='oldPassword' onChange={_onChange} value={credentials.oldPassword} placeholder="••••••••" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="passwordChange" className="formLabel">Nové heslo</label>
                    <input id="passwordChange" type="password" name='password' onChange={_onChange} value={credentials.password} placeholder="••••••••" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="passwordChangeVerification" className="formLabel">Znovu nové heslo</label>
                    <input id="passwordChangeVerification" type="password" name='passwordVerification' onChange={_onChange} value={credentials.passwordVerification} placeholder="••••••••" className="formInput"/>
                </div>
                

                <ul className={`bg-red-200 p-2 rounded-md text-red-600 ${issues.length==0?"hidden":""}`}>
                    {issues.map(issue=>(<li>{issue}</li>))}
                </ul>
                
                <button type="submit" onClick={e=>{e.preventDefault();_onSubmit()}} className="blueButton">
                    Potvrdit změnu
                </button>
            </form>
        </Modal>
    );
}
