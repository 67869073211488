import MockAdapter from "axios-mock-adapter";
import Axios from "axios";
import { BACKEND_URL } from './config';
import { mockUser } from "./mockData/user_current.js";
import { mockOptimize } from "./mockData/optimize.js";
import { mockStartShopping } from "./mockData/start_shopping";
import { mockRecommendations } from "./mockData/recommendations";

export const axios = Axios.create({baseURL: BACKEND_URL});


const mockShops = [{"id":1,"name":"tesco","memberships":[]},{"id":2,"name":"billa","memberships":[]},{"id":3,"name":"albert","memberships":[]},{"id":4,"name":"penny","memberships":[]},{"id":5,"name":"globus","memberships":[]},{"id":6,"name":"kaufland","memberships":[]}]

const mockSearchProduct = {"id":68710,"name":"ananas","baseAmountUnit":"KS","baseAmount":1.0,"type":"G","offersByShop":{"1":{"pieces":1.0,"cost":54.9,"offerId":70007},"2":{"pieces":1.0,"cost":34.9,"offerId":69390},"4":{"pieces":1.0,"cost":69.9,"offerId":69728}},"subproducts":[{"id":69797,"name":"ananas","packageSizeUnit":"KS","packageSize":1.0,"brand":"","category":"ovoce-a-zelenina/ovoce/banany-a-exoticke-ovoce","offersByShop":{"2":{"pieces":1.0,"cost":34.9,"offerId":69390},"4":{"pieces":1.0,"cost":69.9,"offerId":69728}}},{"id":70348,"name":"ananas sušený mrazem kousky crip crop","packageSizeUnit":"G","packageSize":30.0,"brand":"","category":"ovoce-a-zelenina","offersByShop":{"1":{"pieces":1.0,"cost":54.9,"offerId":70007}}}],"category":"ovoce-a-zelenina/ovoce"}
const mockSearchResults = [{"id":42080,"name":"rajčata","normalizedName":"rajcata","bestOffer":{"shopId":1,"offerId":12029,"amount":1.0,"cost":49.9,"offerEnd":"2023-03-28T00:00:00","percentageSale":38,"sale":true},"shopIds":[1,2,3,4],"splittedName":["rajcata"],"baseAmountUnit":"KG","baseAmount":0.5,"group":true},{"id":42075,"name":"rajče cherry","normalizedName":"rajce cherry","bestOffer":{"shopId":1,"offerId":12016,"amount":1.0,"cost":34.9,"sale":false},"shopIds":[1,4,2,3],"splittedName":["rajce","cherry"],"baseAmountUnit":"G","baseAmount":250.0,"group":true},{"id":43429,"name":"bon via rajče cherry oválné 250g, vanička ","normalizedName":"bon via rajce cherry ovalne 250g, vanicka ","bestOffer":{"shopId":2,"offerId":12308,"amount":1.0,"cost":34.9,"sale":false},"shopIds":[2,4],"splittedName":["bon","via","rajce","cherry","ovalne","250g,","vanicka"],"baseAmountUnit":"PKG","baseAmount":1.0,"group":false},{"id":43461,"name":"albert rajčata cherry","normalizedName":"albert rajcata cherry","bestOffer":{"shopId":3,"offerId":12346,"amount":1.0,"cost":39.9,"sale":false},"shopIds":[3],"splittedName":["albert","rajcata","cherry"],"baseAmountUnit":"PKG","baseAmount":1.0,"group":false},{"id":43455,"name":"albert rajčata cherry oválná","normalizedName":"albert rajcata cherry ovalna","bestOffer":{"shopId":3,"offerId":12339,"amount":1.0,"cost":35.0,"sale":false},"shopIds":[3],"splittedName":["albert","rajcata","cherry","ovalna"],"baseAmountUnit":"PKG","baseAmount":1.0,"group":false}]


if (process.env.REACT_APP_MOCK_REST_API) {
    // Mock backend REST API if the environment is configured to do so
    const mock = new MockAdapter(axios, {delayResponse: 200});
    mock.onGet(`/rest/shops`).reply(200, mockShops);
    // Mock login return value
    mock.onPost(`/j_spring_security_check`).reply(200, {
        loggedIn: true,
        success: true
    });
    //mock.onGet(`/rest/users/current`).reply(200, mockUser);
    let tries = 0;
    mock.onGet(`/rest/users/current`).reply(()=>{
        tries++;
        if (tries<=2){
            return [400];
        }else{
            if (tries==4) mockUser.shops = [];
            return [200, mockUser];  
        }
    })
    mock.onPost(`/rest/users`).reply(200)
    
    mock.onPost(`/rest/shoppingList/changeItemState`).reply(200)
    mock.onPost(`/rest/shoppingList`).reply((request)=>{
        const data = JSON.parse(request.data);
        data.id = Math.floor(Math.random() * 10000);
        data.subproducts = [];
        data.shopIds = [1,3];
        return [201, data];  
    })
    mock.onPut(`/rest/shoppingList`).reply((request)=>{
        const data = JSON.parse(request.data);
        console.log(data)
        return [204, data];  
    })
    mock.onPut(/rest\/shoppingItem\/\d+\/check/).reply(200)
    mock.onPut(/rest\/shoppingItem\/\d+\/uncheck/).reply(200)

    mock.onPost(/rest\/users\/blacklist\/\d+/).reply(200)
    mock.onDelete(/rest\/users\/blacklist\/\d+/).reply(200)

    mock.onPost(/rest\/users\/addMembership\/\d+/).reply(200)
    mock.onDelete(/rest\/users\/removeMembership\/\d+/).reply(200)
    let firstAttempt = true;
    mock.onPost(/rest\/users\/addShop\/\d+/).reply(()=>{
        if (firstAttempt){
            firstAttempt=false;
            return [412]
        }else{
            return [200]
        }
    })
    mock.onDelete(/rest\/users\/removeShop\/\d+/).reply(200)
    mock.onPut(/rest\/users\/setMaxShopCount\/\d+/).reply(200)

    mock.onGet(/rest\/users\/requestPasswordResetEmail\/?email=.+/).reply(200)
    mock.onPost(/rest\/users\/changePassword/).reply(200)
    mock.onPost(/rest\/users\/resetPassword/).reply(200)
    
    
    //mock.onGet(/rest\/products/).reply(200, mockSearchProduct)
    mock.onGet(/rest\/products\/getRecommendations/).reply(200, mockRecommendations)
    mock.onGet(/rest\/products\/search/).reply((req)=>{console.log(mockSearchResults);return[200, mockSearchResults]})
    mock.onPost(`/rest/optimize`).reply(200, mockOptimize);
    mock.onPost(`/rest/shopping/start`).reply(200,mockStartShopping);
    mock.onPut(/rest\/shopping\/\d+\/finish/).reply(200,[]);
}