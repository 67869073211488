import {axios} from "../customAxios";
import { useCallback, useEffect, useState } from 'react';
import { REST_URL } from '../config';
import { SearchItemDTO, Unit } from '../dtos';
import { compareSearchItems, delay, mapUnitsToHumanReadableForm, rounded, unitsToString } from '../tools';
import { Loading } from './common';


type Props = {
    source: string,
    placeholder: string,
    onEnter: (a: SearchItemDTO)=>any,
    allowCustom?: boolean
}

export default function AutocompletedForm(props: Props){
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([] as SearchItemDTO[])
    const [text, setText] = useState("")
    const [isFocused, setIsFocused] = useState(false);
    const [preselected, setPreselected] = useState(0);

    const onEnter = useCallback((e: KeyboardEvent)=>{
        if (!isFocused) return; 
        if (options.length === 0) return;
        if (e.key === "Enter"){
            onSelect({...options[preselected]} as SearchItemDTO);//TODO
        }else if (e.key === "ArrowDown"){
            setPreselected(Math.min(options.length-1, preselected+1));
            document.querySelector(`#acoption-${preselected+1}`)?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }else if (e.key === "ArrowUp"){
            setPreselected(Math.max(0, preselected-1));
            document.querySelector(`#acoption-${preselected-1}`)?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }, [options, onSelect, text, props.allowCustom, isFocused]);

    useEffect(()=>{
        const handleClick = () => setIsFocused(false);
        document.addEventListener("click", handleClick)
        document.addEventListener("keydown", onEnter)
        return ()=>{
            document.removeEventListener("click", handleClick);
            document.removeEventListener("keydown", onEnter)
        }
    }, [onEnter])

    

    function onSelect(option: SearchItemDTO){
        setText("");
        setOptions([]);
        setPreselected(0);
        setIsFocused(false);
        props.onEnter(option);
    }

    let latestRequest = "";

    function getMatches(text: string){
        if (text.length < 3) return;
        setIsLoading(true);
        axios.get(`${REST_URL}/${props.source}`, {
            params: {searchString: text}
        }).then(resp =>{
            if (latestRequest !== text) return;
            setIsLoading(false);
            let newOptions = resp.data.sort(compareSearchItems);
            if (props.allowCustom){
                const customOption: SearchItemDTO = {
                    id: null, 
                    name: text,
                    group: false,
                    shopIds: [],
                    popularity: 1,
                    baseAmount: 1,
                    baseAmountUnit: Unit.KS,
                    category: "",
                    subProductsCount: 0,
                };
                newOptions.push(customOption);
            }
            setOptions(newOptions);
            setPreselected(0);
        })
    }
  
    async function onTextChange(e: React.ChangeEvent<HTMLInputElement>){
        const text = e.target.value
        setText(text);
        latestRequest = text;
        await delay(300);
        if (latestRequest !== text) return;
        getMatches(text)
    }
  

    return (
        <div className="w-96 max-w-full z-30 text-black"
        onClick={(e)=>{setIsFocused(true); e.stopPropagation()}}>
            <div className="relative w-full">
                <input 
                    className="h-12 border-gray-300 border-[1px] px-4 outline-none w-full"
                    placeholder={props.placeholder}
                    value={text}
                    type="text"
                    onChange={onTextChange}
                />
                {isLoading ? <Loading className="absolute right-2 top-2.5"/> : ""}
                {isFocused && ((!isLoading && options.length!==0) || (props.allowCustom && text.length>2)) ? (
                    <ul className="bg-white border-[1px] rounded-lg px-4 absolute max-h-[200px] overflow-y-auto scrollbar-hide w-full">
                        {options.map((option: SearchItemDTO, i)=>(
                            <AutocompletedOption id={`acoption-${i}`} key={option.name} preselected={i===preselected} option={option} onSelect={onSelect}/>
                        ))}
                    </ul>
                ) : null}
            </div>
        </div>
    );
}
// && ((!isLoading && options.length!==0) || props.allowCustom)
//onBlur={(e) => {if (!e.currentTarget.contains(e.relatedTarget)) setIsFocused(false); console.log(e)}}>

type AutocompletedOptionProps = {
    option: SearchItemDTO,
    onSelect: (a: SearchItemDTO)=>any,
    preselected?: boolean,
    id: string
}
function AutocompletedOption(props: AutocompletedOptionProps){
    const color = props.preselected ? "bg-slate-200" : "";
    if (props.option.id === null){
        return (
            <li id={props.id} onClick={(e)=>{props.onSelect(props.option)}} className={"hover:cursor-pointer hover:bg-slate-200 items-center py-2 "+color}> 
                Přidat vlastní: {props.option.name}
            </li>
        )
    }
    
    return (
        <li id={props.id} className={`grid grid-cols-2 min-h-10 w-full border-b-[1px] border-solid border-l-gray-300 py-2 hover:cursor-pointer hover:bg-slate-200 ${color}`} 
        onClick={(e)=>{props.onSelect(props.option)}}>
            <div className="capitalize">
                {props.option.name}
            </div>
            <div>
                {props.option.bestOffer?.cost ? `od ${rounded(props.option.bestOffer.cost,1)} Kč/${unitsToString(props.option.bestOffer.packageSize, props.option.bestOffer.packageSizeUnit, props.option.bestOffer.amount)}` : ""}
            </div>
        </li>
    )
}

