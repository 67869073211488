import { Link } from "react-router-dom";
import { notificationState, userState } from "../state";
import {logout} from "../actions"
import {useRecoilState} from 'recoil';
import MyRoutes from "../routing/Routes";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Loading, Toggle } from "./common";
import { useUser } from "../hooks";

type NavBarItem={
    target: string, text: string
}

export default function Navbar(){
    const [user, setUser] = useUser();
    const [collapsed, setCollapsed] = useState(true);
    const [notification, setNotification] = useRecoilState(notificationState);

    useEffect(()=>{
        const handleClick = () => setCollapsed(true)
        document.addEventListener("click", handleClick)
        return ()=>document.removeEventListener("click", handleClick)
    }, [])
    

    const navBarItems: NavBarItem[] = [
        //{target: MyRoutes.productSearch.path, text: "Vyhledat produkt"},
        {target: MyRoutes.shoppingList.path, text: "Nákupní seznam"},
        {target: MyRoutes.parameters.path, text: "Parametry"},
        {target: MyRoutes.shoppings.path, text: "Historie"},
        {target: MyRoutes.settings.path, text: "Nastavení"},
    ]

    if (user.currentShopping.id !== null) navBarItems.splice(2,0,{target: MyRoutes.currentShopping.path, text: "Aktuální nákup"})

    return (
        <nav className={`bg-slate-800 text-slate-300 font-medium px-4 sticky top-0 z-40`} onClick={e=>e.stopPropagation()}>
            <div className="flex lg:flex-row flex-col justify-between">
                <div className="p-2 w-50 text-lg flex gap-2">
                    <Link className="pt-0.5" to={MyRoutes.shoppingList.path}>
                        Shupty
                    </Link>
                    <Loading className={"transition-all duration-500 opacity-0"} id="queueLoading"/>
                </div>
                
                <FaBars className="icon absolute top-1 right-2 lg:hidden" onClick={()=>setCollapsed(!collapsed)}/>
                
                <div className={"lg:max-h-full transition-all duration-300 overflow-hidden " + (collapsed?"max-h-0":"max-h-80 w-full")} onClick={()=>setCollapsed(!collapsed)}>
                    <ul className="flex lg:flex-row flex-col lg:pt-1 justify-end lg:pr-2">
                        {navBarItems.map((item: NavBarItem,i: number)=>(
                            <Link key={i} to={item.target} className="p-2 border-t lg:border-none border-slate-400 hover:bg-slate-900 hover:cursor-pointer">{item.text}</Link>
                        ))}
                        <li className="">
                            <button className="blueButton my-4 lg:my-0 lg:mx-2" onClick={()=>logout(setUser,setNotification)}>Odhlásit se</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}