import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import { useRecoilState, useRecoilValue } from "recoil";
import { toggleBlacklistedProduct } from "../actions";
import { SubproductDTO, User } from "../dtos";
import { useUser } from "../hooks";
import { notificationState, userState } from "../state";
import{Offer} from "./Offers";
import { unitsToString } from "../tools";

//disabled={disabled} data={subproduct}
type Props={
    disabled: boolean,
    data: SubproductDTO
}
export default function SubProduct(props: Props){    //icon greyed on remove action
    const item = props.data;
    const [user,setUser] = useUser();
    const [notification, setNotification] = useRecoilState(notificationState);

    return (
        <div className={"subItem items-center flex gap-2 transition-all " + (props.disabled ? "opacity-50" : "opacity-100")}>
            <div className="flex items-center justify-between flex-wrap w-full gap-2">
                <div className="font-bold col-span-4 capitalize">{item.name}</div>
                {!item.bestOffer ? "" : <div className="col-span-2">
                    {/*`${item.packageSize ? item.packageSize : "Váženo v"} ${item.packageSizeUnit.toLowerCase()}`*/}
                     <Offer offer={item.bestOffer}/>  
                </div>}  
            </div>
            <div className="hover:cursor-pointer hover:text-slate-800 text-slate-500">
                {!props.disabled ? 
                    <AiFillCloseCircle size="30" onClick={()=>toggleBlacklistedProduct(item.id, true, setUser, setNotification)}/> : 
                    <AiFillPlusCircle size="30" onClick={()=>toggleBlacklistedProduct(item.id, false, setUser, setNotification)}/>}
            </div>
        </div>
    );
}
