import ShoppingItem from "../components/ShoppingItem";
import { useRecoilState, useRecoilValue } from "recoil";
import { notificationState, userState } from "../state";
import { ButtonFront } from "../components/common";
import { ShoppingDTO, ShoppingItemDTO, User } from "../dtos";
import { finishShopping, toggleShoppingItemState } from "../actions";
import Shopping from "../components/Shopping";
import OngoingShopping from "../components/OngoingShopping";
import { useNavigate } from "react-router-dom";
import MyRoutes from "../routing/Routes";
import { useUser } from "../hooks";

export default function OptimizedPage() {
    const [user, setUser] = useUser();
    const [notification, setNotification] = useRecoilState(notificationState);
    const navigate = useNavigate();
    
    function _finishShopping(){
        finishShopping(setUser, setNotification);
        navigate(MyRoutes.shoppingList.path);
    }

    if (user.currentShopping.id === null){
        return (
            <div className={"page pt-20"}>
                Nejprve zadejte váš nákupní seznam a spusťte optimalizaci.
                <div>
                    <button className="blueButton" onClick={()=>navigate(MyRoutes.shoppingList.path)}>
                        Přejít do nákupního seznamu
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={"page"}>
            <div className="listWrapper">
                <OngoingShopping showSales={true} shopping={user.currentShopping} fullView={true}/>
            </div>
            <ButtonFront text="Dokončit nákup" onClick={_finishShopping}/>
        </div>
    );
}

/*

            <div className="listWrapper">
                {user.currentShopping?.shoppingItems?.map((item: ShoppingItemDTO, i:number)=>{
                    return <ShoppingItem key={i} data={item} onStateUpdate={(id,state)=>toggleShoppingItemState(id, state, user, setUser, setNotification)} showCheckbox={true}/>
                })}
            </div>


*/