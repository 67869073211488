import { OfferDTO } from "../dtos";
import { rounded, stringDateToDayAndMonth, unitsToString } from "../tools";

type ShopIconProps = {
    shopName: string,
    color?:string
}
export function ShopIcon(props: ShopIconProps){
    return (
        <div className={`h-8 w-8 min-w-8 rounded-full overflow-hidden flex items-center ${props.color? props.color : "bg-white"}`}>
            <img src={`../icons/${props.shopName}.png`} alt={props.shopName}/>
        </div>
    );
}

type Props = {
    offer: OfferDTO,
    bestOffer: boolean,
    shopName: string,
}
export function ShopWithPrice(props: Props){
    function showDate(){
        if (!props.offer.sale || !props.offer.offerEnd) return "";
        return ` do ${stringDateToDayAndMonth(props.offer.offerEnd)}`
    }

    function showPercentageSale(){
        if (!props.offer.percentageSale) return "";
        return ` (-${props.offer.percentageSale} %)`
    }

    function showAmount(){
        if (!props.offer.packageSizeUnit) return "";
        return "/ "+unitsToString(props.offer.packageSize, props.offer.packageSizeUnit, props.offer.amount);
    }

    return (
        <div className={"flex h-10 items-center gap-3 rounded-3xl p-1 pr-3 flex-shrink " + 
            (props.bestOffer ? "bg-green-600" : (props.offer.sale ? "bg-yellow-500" : "bg-slate-600 dark:bg-slate-300"))}>
            <ShopIcon shopName={props.shopName}/>
            <div className={`${(props.offer.sale || props.bestOffer) ? "text-gray-100":"text-gray-300 dark:text-gray-700"}`}>
                {`${rounded(props.offer.cost,1)} Kč${showPercentageSale()}${showAmount()}${showDate()}`}
            </div>
        </div>
    );
}