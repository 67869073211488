import { useRecoilValue } from "recoil";
import { OfferDTO, OfferMap, ShopDTO } from "../dtos";
import { availableShopsState } from "../state";
import {ShopWithPrice} from "./ShopWithPrice";

type Props={
    offers: OfferMap
}

export function Offers(props: Props) {
    const shops = useRecoilValue(availableShopsState);

    function showOffers(offers: OfferMap){
        let bestOffer:OfferDTO|null = null;
        Object.keys(offers).forEach((shopId:string)=>{
            const offer = offers[shopId] as OfferDTO;
            if (bestOffer === null || offer.cost < bestOffer.cost) bestOffer=offer;
        })
        return Object.keys(offers).map((shopId:string)=>{
            const offer = offers[shopId] as OfferDTO;
            return(
                <ShopWithPrice key={shopId} 
                    offer={offer} 
                    shopName={shops.find((s:ShopDTO)=>s.id===parseInt(shopId))?.name} 
                    bestOffer={offer.offerId===bestOffer?.offerId}/>
            );                    
        })
    }

    return (
        <div className="flex flex-wrap gap-2">
            {showOffers(props.offers)}
        </div>
    );
}


type OfferProps={
    offer: OfferDTO,
}
export function Offer(props: OfferProps) {
    const shops = useRecoilValue(availableShopsState);
    return (
        <ShopWithPrice offer={props.offer} 
            shopName={shops.find((s: ShopDTO)=>s.id==props.offer.shopId)?.name}
            bestOffer={false}
            />
    );
}

