import { FaPercent, FaTrash } from 'react-icons/fa';
import SubProduct from "./SubProduct";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "../state";
import {Offer} from "./Offers";
import { Toggle, TristateSwitch } from './common';
import Collapsable from './Collapsable';
import { OfferDTO, ShoppingListItemDTO, ShoppingListItemState, SubproductDTO, User } from '../dtos';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useUser } from '../hooks';
import { unitsToString } from '../tools';


type Props = {
    data: ShoppingListItemDTO,
    isSuggestion?: boolean,
    onStateUpdate: (sli: ShoppingListItemDTO, newState: ShoppingListItemState)=>any,
    onUpdate: (sli: ShoppingListItemDTO, sendToServer?: boolean)=>any,
}
export default function ShoppingListItem(props: Props) {
    const [expanded, setExpanded] = useState(false);
    const [user, setUser] = useUser()

    let item = props.data;

    function _onChange(e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>){
        const newData = {...props.data} as ShoppingListItemDTO
        newData[e.target.name] = e.target.value;
        let sendToServer = e.target.name !== "amount";
        props.onUpdate(newData, sendToServer);
    }

    function _onCompletingAmountInput(){
        const newData = {...props.data} as ShoppingListItemDTO
        props.onUpdate(newData, true);
    }

    function _changeState(newState: ShoppingListItemState){
        const newData = {...props.data}
        newData.state = newState;
        props.onStateUpdate(props.data, newState);
    }

    function format(){
        //let formattingString = props.data.state===ShoppingListItemState.NACT && !props.isSuggestion ? " opacity-50" : " opacity-100";
        let formattingString = "";
        if (!props.data.productId)
            formattingString += " bg-blue-300 dark:bg-blue-700"
        return formattingString
    }

    let bestOffer = props.data.bestOffer;
    if (props.isSuggestion){
        bestOffer = item.subproducts.reduce((result: OfferDTO|null|undefined, current: SubproductDTO|null|undefined)=>{
            if (!result) return current?.bestOffer;
            if (!current?.bestOffer) return result;
            if (result.cost < current.bestOffer.cost)
                return result;
            return current?.bestOffer;
        }, props.data?.bestOffer);
    }

    let itemOS = ShoppingListItemState.OS === item.state;

    return (
        <div className={"card transition-all" + format()}>
            <div className={`flex lg:flex-nowrap flex-wrap w-full ${props.data.productId && !props.isSuggestion ? "hover:cursor-pointer":""} justify-between items-center gap-y-1`} onClick={()=>setExpanded(!expanded)}>
                <div className='flex items-center gap-4 font-bold mr-auto capitalize basis-3/5 lg:basis-2/5'>
                    <div>
                        {props.isSuggestion || item.state === ShoppingListItemState.NACT ? 
                            <AiFillPlusCircle className="hover:cursor-pointer" size="30" onClick={(e)=>{_changeState(ShoppingListItemState.ACT); e.stopPropagation()}}/>:
                            <FaTrash className='icon w-7 h-7' onClick={(e)=>{_changeState(ShoppingListItemState.NACT); e.stopPropagation()}}/>
                        }
                    </div>
                    {props.data.name}
                </div>
                
                <div className="flex basis-2/5 lg:basis-1/5">
                    {props.isSuggestion ? unitsToString(item.amount, item.amountUnit) :
                        <>
                            <input className="formInput" type="number" name="amount" value={item.amount} onClick={e=>e.stopPropagation()} onChange={_onChange} onBlur={_onCompletingAmountInput}/>
                            <select className="formInput" name="amountUnit" value={item.amountUnit} onClick={e=>e.stopPropagation()} onChange={_onChange}>
                                <option value="KG">kg</option>
                                <option value="G">g</option>
                                <option value="L">l</option>
                                <option value="ML">ml</option>
                                <option value="KS">ks</option>
                                <option value="PKG">bal</option>
                            </select>
                        </>
                    }
                </div>

                <div className='font-bold lg:basis-2/5'>
                    <div className='justify-end flex'>
                        {bestOffer ? <Offer offer={bestOffer}/> : ""}
                    </div>
                </div>
            </div>
            
            
            <Collapsable expanded={props.data.productId && !props.isSuggestion ? expanded : false}>
                <div className='flex items-center gap-2'>
                    <label htmlFor={`maxCost${item.id}`}>Maximální cena</label>
                    <input className="formInput" type="number" name="maxCost" id={`maxCost${item.id}`} value={item.maxCost?item.maxCost:undefined} onChange={_onChange}/>
                    Kč
                </div>
                <div className='flex gap-8'>
                    Pouze ve slevě
                    <Toggle checked={itemOS} color='bg-yellow-500' onChange={()=>_changeState(itemOS ? ShoppingListItemState.ACT:ShoppingListItemState.OS)}/>
                </div>
                <div>
                    {props.data.subproducts.map((subproduct: SubproductDTO) => {
                        const disabled = user.blacklistedProducts.includes(subproduct.id);
                        return (<SubProduct key={subproduct.id} disabled={disabled} data={subproduct}/>)
                    })}
                </div>
            </Collapsable>
        </div>
    );
}
