import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { User } from './dtos';
import { globalStateCopy, userState } from './state';

//listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions | undefined
export const useEvent = (type: string, ref: React.MutableRefObject<any>, listener:EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions | undefined) => {
    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener(type, listener, options);
            return () => ref.current.removeEventListener(type, listener, options);
        }
        return ()=>{};
        }, [ref.current, listener, options]
    );
};

// export const useUser = ():[user:User,_setUset:(user: User)=>any] => {
//     const [user,setUser] = useRecoilState<User>(userState);
//     function _setUser(newUser: User){
//         globalStateCopy.user = {...newUser} as User;
//         setUser(user);
//     }
//     return [user, _setUser];
// };

export const useUser = (): [user: User, setUser: (newUser: User) => void] => {
    const [user, setUser] = useRecoilState<User>(userState);
  
    const _setUser = (newUser: User) => {
        setUser(newUser);
        globalStateCopy.user = {...newUser} as User;
    };
  
    return [user, _setUser];
  };

// const useTargetEvent = (type: string, ref: React.MutableRefObject<any>, handler: (e:Event)=>any, ignoreChildren: boolean)=>{
//     useEffect(() => {
//         const listener = (event: Event) => {
//             // Do nothing if clicking ref's element or descendent elements
//             if (!ref.current || (ignoreChildren && ref.current.contains(event.target))) {
//                 return;
//             }
//             handler(event);
//         };

//         if (ref.current) {
//             ref.current.addEventListener(type, listener, false);
//             return () => ref.current.removeEventListener(type, listener);
//         }
//         return ()=>{};
//         }, [ref.current, handler]
//     );
// }

// export const useFocusIn = (ref: React.MutableRefObject<any>, handler: (e:Event)=>any) => useTargetEvent('focusin', ref, handler, false);
// export const useFocusOut = (ref: React.MutableRefObject<any>, handler: (e:Event)=>any) => useTargetEvent('focusout', ref, handler, false);

//useFocusOut(ref, () => setIsFocused(false));
//useFocusIn(ref, e => {setIsFocused(true); e.stopPropagation()});
//useEvent("click", ref, (e)=>{setIsFocused(true); e.stopPropagation()});