import { OfferDTO, ProductShoppingItemDTO, SearchItemDTO, ShopDTO, ShoppingDTO, ShoppingItemDTO, ShoppingListItemDTO, ShoppingListItemState, SubproductDTO, Unit } from "./dtos";

export function compareShoppingItems(a: ShoppingItemDTO,b: ShoppingItemDTO){
    if (a.confirmed !== b.confirmed)
        return a.confirmed ? 1 : -1;
    if ((a.type=="REQ" || a.type=="EXTRA") && (b.type=="REQ" || b.type=="EXTRA") && a.shopName !== b.shopName)
        return a.shopName<b.shopName ? -1 : 1;
    if ((a.type=="REQ" || a.type=="EXTRA") && (b.type=="REQ" || b.type=="EXTRA") && a.category !== b.category)
        return a.category<b.category ? -1 : 1;
    return a.name < b.name ? -1 : 1;
}

export function compareShoppingListItems(a: ShoppingListItemDTO,b: ShoppingListItemDTO){
    const inactiveStates = [ShoppingListItemState.NACT, ShoppingListItemState.HID];
    if (inactiveStates.includes(a.state) && inactiveStates.includes(b.state)){
        if (a.previousPurchasesCount !== b.previousPurchasesCount)
            return a.previousPurchasesCount<b.previousPurchasesCount ? -1 : 1;
    }
    if (a.state !== b.state){
        if (a.state === ShoppingListItemState.NACT) return 1;
        if (b.state === ShoppingListItemState.NACT) return -1;
        if (a.state === ShoppingListItemState.OS) return 1;
        else return -1;
    }
    if (a.category !== b.category && a.category !== null && b.category !== null)
        return a.category<b.category ? -1 : 1;
    
    if (a.name !== b.name)
        return a.name<b.name ? -1 : 1;
    return 0;
}//1: b first, -1: a first

export function compareSearchItems(a: SearchItemDTO,b: SearchItemDTO){
    if (a.shopIds.length !== b.shopIds.length)
        return a.shopIds.length<b.shopIds.length ? 1 : -1;
    if (a.popularity !== b.popularity)
        return a.popularity<b.popularity ? 1 : -1;
    if (a.group !== b.group)
        return a.group ? -1 : 1;
    if (a.name !== b.name)
        return a.name<b.name ? -1 : 1;
    return 0;
}

export function compareShoppings(a: ShoppingDTO,b: ShoppingDTO){
    if (a.missingItems !== b.missingItems)
       return a.missingItems < b.missingItems ? -1 : 1;
    if (a.totalCost !== b.totalCost)
        return a.totalCost<b.totalCost ? -1 : 1;
    if (a.shopIds.length !== b.shopIds.length)
        return a.shopIds.length<b.shopIds.length ? 1 : -1;
    return 1;
}

export function compareShoppingsByDate(a: ShoppingDTO,b: ShoppingDTO){
    if (a.timestamp !== b.timestamp && a.timestamp!==undefined && b.timestamp!==undefined)
        return a.timestamp < b.timestamp ? 1 : -1;
    return 0;
}


export function compareSubproducts(a: SubproductDTO,b: SubproductDTO){
    if (a.bestOffer === undefined) return 1;
    if (b.bestOffer === undefined) return -1;
    if (a.bestOffer!==undefined && b.bestOffer!==undefined)
        return a.bestOffer.cost < b.bestOffer.cost ? -1 : 1;
    return 0;
}
export function compareShops(a: ShopDTO,b: ShopDTO){
    return a.name < b.name ? 1 : -1;
}

export function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function rounded(number: number, decimals: number){
    const power = Math.pow(10, decimals);
    return Math.round(number*power)/power
}

export function stringDateToDayAndMonth(strDate: string|undefined, includeYear?: boolean){
    if (strDate===undefined) return "";
    const date = new Date(strDate);
    return `${date.getDate()}.${date.getMonth()+1}.${includeYear?date.getFullYear():""}`
}

export function stringifyDate(date: Date){
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

export function mapSearchItemToShoppingListItem(item: SearchItemDTO): ShoppingListItemDTO{
    return {
        id: null,
        type: "P",
        amount: item.baseAmount,
        amountUnit: item.baseAmountUnit,
        previousPurchasesCount: 0,

        productId: item.id,
        name: item.name,
        group: true,
        bestOffer: item.bestOffer,
        shopIds: item.shopIds,

        maxCost: null,
        lastBuyDate: null,
        state: ShoppingListItemState.NACT,
        category: null,
        isRecommendation: true,

        subproducts: [],
    }
}

export function mapRecommendationToShoppingItem(searchItem: SearchItemDTO, shops: ShopDTO[]): ProductShoppingItemDTO{
    if (!searchItem.bestOffer) return {} as ProductShoppingItemDTO;

    const shoppingItem = {
        type: "EXTRA",
        offerId: searchItem.bestOffer.offerId,
        productId: searchItem.id,
        amount: 1,
        originalAmount: 1,
        confirmed: false,

        cost: searchItem.bestOffer.cost,
        originalCost: searchItem.bestOffer.cost,
        percentageSale: searchItem.bestOffer.percentageSale,
        offerEnd: searchItem.bestOffer.offerEnd,
        
        name: searchItem.name,
        brand: "",
        packageSize: searchItem.bestOffer.packageSize,
        packageSizeUnit: searchItem.bestOffer.packageSizeUnit,
        category: "",
        
        shopName: shops?.find((s: ShopDTO)=>s.id===(searchItem.bestOffer as OfferDTO).shopId)?.name,
        membershipName: "",
    } as ProductShoppingItemDTO;

    return shoppingItem;
}

export function mapUnitsToHumanReadableForm(units: Unit){
    switch (units) {
        case Unit.G: return "g";
        case Unit.KG: return "kg";
        case Unit.L: return "l";
        case Unit.ML: return "ml";
        case Unit.KS: return "ks";
        case Unit.PKG: return "balení";
        default: return units;
    }
}

export function unitsToString(packageSize: number|undefined, packageSizeUnit: Unit|undefined, amount?: number){
    if (!packageSizeUnit) return "";
    let amountString = (amount && amount!=1) ? `${rounded(amount,3)} x ` : "";
    return `${amountString}${packageSize ? packageSize : ""} ${mapUnitsToHumanReadableForm(packageSizeUnit)}`
}

export function scrollToTop(element: Element) {
    const currentScroll = element.scrollTop;
    if (currentScroll > 0.1) {
      window.requestAnimationFrame(function() {
        scrollToTop(element);
      });
      element.scrollTop = currentScroll - (currentScroll / 8);
    }
  }