import { useState } from "react";
import { ProductShoppingItemDTO, ShoppingItemDTO } from "../dtos";
import { mapUnitsToHumanReadableForm, rounded, stringDateToDayAndMonth, stringifyDate, unitsToString } from "../tools";
import { ShopIcon } from "./ShopWithPrice";

type Props={
    data: ProductShoppingItemDTO,
    onStateUpdate: (id: number, state: boolean) => any,
    onAmountUpdate?: (updated: ProductShoppingItemDTO, deleteOnZero?: boolean) => any,
    showCheckbox: boolean
}
export default function ShoppingItem(props: Props) {
    let item = props.data;
    const [expanded, setExpanded] = useState(false);

    function _changeState(e: React.ChangeEvent<HTMLInputElement>){
        if (!item.id) return;
        props.onStateUpdate(item.id, e.target.checked);
    }

    function _onAmountUpdate(e: React.ChangeEvent<HTMLInputElement>){
        const newData = {...props.data} as ProductShoppingItemDTO
        newData.amount = parseFloat(e.target.value);
        if (props.onAmountUpdate) props.onAmountUpdate(newData);
    }

    function _deleteIfNoAmount(){
        const newData = {...props.data} as ProductShoppingItemDTO
        if (props.onAmountUpdate) props.onAmountUpdate(newData, true);
    }

    function showAmount(){
        if (!props.onAmountUpdate) return unitsToString(item.packageSize, item.packageSizeUnit, item.amount);
        return (
            <>
                <input className="formInput w-20" type="number" name="amount" min={0} value={item.amount} onClick={e=>e.stopPropagation()} onChange={_onAmountUpdate} onBlur={_deleteIfNoAmount}/>
                x {unitsToString(item.packageSize, item.packageSizeUnit)}
            </>
        )
    }

    return (
        <div className={"subItem transition-all " + (item.confirmed ? "opacity-50" : "opacity-100")}>
            <div className="flex gap-2 items-center">
                <div className="capitalize">
                    <ShopIcon shopName={item.shopName} color="bg-white border border-slate-400 border-[2px]"/>
                </div>
                <div className={"lg:grid-cols-8 grid w-full gap-2 grid-cols-4 hover:cursor-pointer items-center font-bold"} 
                    onClick={()=>{
                        if (item.brand || item.percentageSale || item.offerEnd || item.membershipName) setExpanded(!expanded);
                        else if (item.id) props.onStateUpdate(item.id, !item.confirmed);
                    }}>
                    <div className="col-span-4 capitalize">
                        {item.name}
                    </div>
                    <div className="font-normal text-sm flex items-center gap-2 col-span-3">
                        {showAmount()}
                    </div>
                    <div>
                        {rounded(item.cost,1)} Kč
                    </div>       
                </div>
                {!props.showCheckbox ? "" : (
                    <div>
                        <input className="roundCheckbox" type="checkbox" checked={item.confirmed} onChange={_changeState}/>
                    </div>
                )}
            </div>
            <div className={"scrollbar-hide overflow-y-scroll transition-[max-height] duration-500 " + (expanded ? `max-h-96 ease-in` : "max-h-0 ease-out")}>
                <div className={expanded?"scale-100 transition delay-100 duration-[0s]":"scale-0"}>
                    <div className="p-2 pr-4 flex flex-col gap-2">
                        {item.brand?<div>Značka: {item.brand}</div>:null}
                        {item.percentageSale?<div>Sleva: {item.percentageSale} %</div>:null}
                        {item.offerEnd?<div>Končí: {stringDateToDayAndMonth(item.offerEnd,true)}</div>:null}
                        {item.membershipName?<div>Bonus klub: {item.membershipName}</div>:null}
                    </div>
                </div>
            </div>
        </div>
        
    
    );
}