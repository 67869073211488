import ShoppingListItem from "../components/ShoppingListItem";
import AutocompletedForm from "../components/AutocompletedForm";
import { useRecoilState, useRecoilValue } from "recoil";
import { globalStateCopy, notificationState, recommendationsState, userState } from "../state";
import { addItemToShoppingList, updateShoppingListItem, changeShoppingListItemState, getRecommendations, fetchShoppingList } from "../actions";
import { useNavigate, useLocation } from "react-router-dom";
import MyRoutes from "../routing/Routes";
import { ButtonFront } from "../components/common";
import { SearchItemDTO, ShoppingListItemDTO, ShoppingListItemState, User } from "../dtos";
import { useCallback, useEffect, useRef, useState } from "react";
import { compareShoppingListItems, mapSearchItemToShoppingListItem, scrollToTop } from "../tools";
import { useUser } from "../hooks";

type Filter = "ACT_OS" | "NACT" | "ALL" | "RECOMMENDED"
const filterFunctions = {
    "ACT_OS": (s:ShoppingListItemDTO)=>s.state==="ACT"||s.state==="OS",
    "ALL": (s:ShoppingListItemDTO)=>s.state!=="HID",
    "NACT": (s:ShoppingListItemDTO)=>s.state==="NACT"
}
const sortFunctions = {
    "ACT_OS": compareShoppingListItems,
    "ALL": compareShoppingListItems,
    "NACT": compareShoppingListItems
}

const ITEMS_PER_LOAD = 10;

export default function ShoppingListPage() {
    const [user, setUser] = useUser();
    const recommendations = useRecoilValue<SearchItemDTO[]>(recommendationsState);
    const navigate = useNavigate();
    const [notification, setNotification] = useRecoilState(notificationState);
    const [filter, setFilter] = useState<Filter>("ACT_OS")
    const location = useLocation();
    const [allRecommendationsAsItems, setAllRecommendationsAsItems] = useState<ShoppingListItemDTO[]>([]);
    const pageRef = useRef(null);
    const [displayedItemsCount, setDisplayedItemsCount] = useState<number>(ITEMS_PER_LOAD);

    useEffect(() => {
        console.log('Component is navigated to:', location.pathname);
        // Perform some action here
        if (globalStateCopy.shoppingListUpdateNeeded){
            fetchShoppingList(setUser, setNotification);
        }
    }, [location]);

    useEffect(() => {
        setAllRecommendationsAsItems(recommendations.map((recommendation)=>mapSearchItemToShoppingListItem(recommendation)));
    }, [recommendations]);

    useEffect(() => {
        setDisplayedItemsCount(ITEMS_PER_LOAD);
    }, [filter]);


    let allListItems = filter === "RECOMMENDED" ? allRecommendationsAsItems.map(r=>{
        const existingSli = user.shoppingListItems.find(sli=>sli.productId===r?.productId)
        if (existingSli && existingSli.state === "ACT") return null;
        if (existingSli) return existingSli;
        return r;
    }).filter(r=>r!=null) as ShoppingListItemDTO[] : user.shoppingListItems.filter(filterFunctions[filter]);


    const handleScroll = useCallback(() => {
        const pageElement = pageRef.current;
        if (!pageElement) return;
        const { scrollTop, scrollHeight, clientHeight } = pageElement;
        if (displayedItemsCount >= allListItems.length || scrollTop + clientHeight < scrollHeight-clientHeight/2) return;
        setDisplayedItemsCount(displayedItemsCount + ITEMS_PER_LOAD);
    }, [allListItems, displayedItemsCount, pageRef]);
    

    function _updateShoppingListItemState(shoppingListItem: ShoppingListItemDTO, newState: ShoppingListItemState){
        changeShoppingListItemState(shoppingListItem, newState, setUser, setNotification);
    }

    function _addRecommendationToShoppingList(shoppingListItem: ShoppingListItemDTO, newState: ShoppingListItemState){
        if (newState === "ACT" || newState === "OS"){
            const recommendation = recommendations.find(r=>r.id===shoppingListItem.productId)
            if (!recommendation) return;
            addItemToShoppingList(recommendation, setUser, setNotification)
        }
    }

    function _updateShoppingListItem(shoppingListItem: ShoppingListItemDTO, sendToServer: boolean = true){
        updateShoppingListItem(shoppingListItem, setUser, setNotification, sendToServer);
    }

    function showShoppingListItems(){
        if (user.role === "TO_VERIFY"){
            return <div className="text-center">Vaše registrace ještě nebyla ověřena. Po ověření budete moci využívat plné funkce aplikace.</div>
        }

        // if (filter === "RECOMMENDED"){
        //     return (
        //         <>
        //             <div className="listWrapper">
        //                 {recommendations
        //                     .slice(salesPage*50, salesPage*50+50)
        //                     .map((recommendation)=>{
        //                         const existingSli = user.shoppingListItems.find(sli=>sli.productId===recommendation.id)
        //                         if (!existingSli){
        //                             return <ShoppingListItem isSuggestion={true} data={mapSearchItemToShoppingListItem(recommendation)} key={recommendation.name} onUpdate={()=>{}} onStateUpdate={_addRecommendationToShoppingList}/>
        //                         }
        //                         if (existingSli.state === "ACT") return null;
        //                         //just display the existing item
        //                         return <ShoppingListItem isSuggestion={true} data={existingSli} key={existingSli.name} onUpdate={_updateShoppingListItem} onStateUpdate={_updateShoppingListItemState}/>
        //                     }).filter((item)=>item!==null)
        //                 }
        //                 <div className="flex gap-2 w-full">
        //                     {salesPage===0?null:<button className="blueButton w-32" onClick={(e)=>{setSalesPage(salesPage-1); scrollToTop((e.target as Element).parentElement?.parentElement as Element)}}>Předchozí</button>}
        //                     {salesPage*50+50>=recommendations.length ? null : <button className="blueButton w-32" onClick={(e)=>{setSalesPage(salesPage+1); scrollToTop((e.target as Element).parentElement?.parentElement as Element)}}>Další</button>}
        //                 </div>
        //             </div>
                    
        //         </>
        //     )
        // }

        const listItems = allListItems.slice(0, displayedItemsCount).map((item) => {
            return <ShoppingListItem isSuggestion={filter==="RECOMMENDED"} data={item} key={item.name} 
                onUpdate={item.isRecommendation ? ()=>{} : _updateShoppingListItem} 
                onStateUpdate={item.isRecommendation ? _addRecommendationToShoppingList : _updateShoppingListItemState} />
        });
        return (
            <div className="listWrapper" ref={pageRef} onScroll={handleScroll}>
                {listItems.length>0 ? listItems : <div className="text-center">Nákupní seznam je prázdný</div>}
                {displayedItemsCount < allListItems.length ? <div className="text-center">Načítám...</div> : null}
            </div>
        )
    }

    
    return (
        <div className="page">
            <AutocompletedForm source="products/search" placeholder="Produkt" onEnter={c => {addItemToShoppingList(c, setUser, setNotification); setFilter("ACT_OS")}} allowCustom={true}/>
            <Filter filter={filter} setFilter={setFilter}/>
            {showShoppingListItems()}
            <ButtonFront text="Optimalizovat" onClick={()=>navigate(MyRoutes.parameters.path)}/>
        </div>
    );
}

type FilterProps = {filter: Filter, setFilter: (f: Filter)=>any}
function Filter({filter, setFilter}: FilterProps){
    let position;
    if (filter === "RECOMMENDED") position= "-left-32";
    else if (filter === "ACT_OS") position="left-0";
    else position="left-32"

    return (
        <div className="w-96 max-w-full">
            <div style={{"-webkit-tap-highlight-color": "transparent"} as any} className="grid grid-cols-3 justify-between text-center hover:cursor-pointer pt-2 formLabel m-0">
                <div className="pb-2 border-b-4" onClick={()=>setFilter("RECOMMENDED")}>Akce</div>
                <div className="border-b-4" onClick={()=>setFilter("ACT_OS")}>Nákupní seznam</div>
                <div className="border-b-4" onClick={()=>setFilter("NACT")}>Dříve koupené</div>
            </div>
            <div className="grid grid-cols-3">
                <div></div>
                <div className={`transition-all duration-500 relative top-[-4px] ${position} border-t-4 border-green-400`}></div>
            </div>
        </div>
    )
}