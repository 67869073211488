import { REST_URL, LOGIN_URL } from './config';
import {RequestQueue} from "./requestQueue"
import { defaultCurrentShopping, defaultUserState, globalStateCopy } from "./state";
import {axios} from "./customAxios";
import { compareShoppingItems, compareShoppingListItems, compareShoppings, compareShoppingsByDate, compareShops, compareSubproducts } from './tools';
import { ChangePasswordDTO, ErrorResponse, LoginCredentials, MissingItemDTO, NotificationState, ProductDTO, RegisterCredentials, ResetPasswordDTO, SearchItemDTO, ShopDTO, ShoppingDTO, ShoppingItemDTO, ShoppingListItemDTO, ShoppingListItemState, ShoppingState, Unit, User, UserDTO } from './dtos';
import MyRoutes from './routing/Routes';


function displayError(e: any, message: string, setNotification: (n: NotificationState)=>any){
    if ([403,404,409].includes(e?.status)){ 
        message += e.data.message;
    }
    setNotification({text:message,type:"ERROR",show:true});
    console.log(e);
}


function updateStateShoppingListItems(user: User, setUser: (user: User)=>any, shoppingListItems: ShoppingListItemDTO[], updateUser: boolean){
    const newState = {...user};
    newState.shoppingListItems = shoppingListItems;
    newState.shoppingListItems.sort(compareShoppingListItems)
    if (updateUser) setUser(newState);
    return newState;
}


export async function changeShoppingListItemState(shoppingListItem: ShoppingListItemDTO, newState: ShoppingListItemState, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        await RequestQueue.postJson(`${REST_URL}/shoppingList/changeItemState`, {shoppingListItemId: shoppingListItem.id, state: newState})
        const newItem = {...globalStateCopy.user.shoppingListItems.find(i => (i.id === shoppingListItem.id))} as ShoppingListItemDTO;
        newItem.state = newState;
        const newList = globalStateCopy.user.shoppingListItems.filter(i => (i.id !== shoppingListItem.id));
        newList.push(newItem);
        updateStateShoppingListItems(globalStateCopy.user, setUser, newList, true);
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function updateShoppingListItem(shoppingListItem: ShoppingListItemDTO, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any, sendToServer: boolean){    //TODO change this to lower server traffic to just show baseAmount price
    try{
        let newList;
        if (sendToServer){
            const response = await RequestQueue.putJson(`${REST_URL}/shoppingList`, shoppingListItem);
            newList = globalStateCopy.user.shoppingListItems.filter(i => (i.id !== shoppingListItem.id));
            newList.push(response.data);
        }else{
            newList = globalStateCopy.user.shoppingListItems.filter(i => (i.id !== shoppingListItem.id));
            newList.push(shoppingListItem);
        }
        updateStateShoppingListItems(globalStateCopy.user, setUser, newList, true);
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function fetchShoppingList(setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        const response = await RequestQueue.getJson(`${REST_URL}/shoppingList`)
        updateStateShoppingListItems(globalStateCopy.user, setUser, response.data, true);
        globalStateCopy.shoppingListUpdateNeeded = false;
    }catch(e){
        displayError(e, "Chyba při komunikaci se serverem. ", setNotification);
    }
}

export async function addItemToShoppingList(searchItem: SearchItemDTO, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    //TODO: unblacklist it first - backend
    let existingSli;
    if (searchItem.id === null){    //note
        existingSli = globalStateCopy.user.shoppingListItems.find(sli => sli.name == searchItem.name)
    }else{  //referes to product
        existingSli = globalStateCopy.user.shoppingListItems.find(sli => sli.productId == searchItem.id)
    }
    if (existingSli){
        if (existingSli.state !== "ACT"){
            //TODO existingSli might change before completing
            await changeShoppingListItemState(existingSli, ShoppingListItemState.ACT, setUser, setNotification)
            setNotification({text:"Položka nalezena ve vašem nákupním seznamu a aktivována.",type:"SUCCESS",show:true})
        }
        return;
    }

    const newShoppingListItem: ShoppingListItemDTO = {
        id: null,
        type: searchItem.id ? "P" : "N",
        amount: searchItem.baseAmount ? searchItem.baseAmount : 1,
        amountUnit: searchItem.baseAmountUnit ? searchItem.baseAmountUnit : Unit.KS,
        maxCost: null,
        priority: 1,
        state: ShoppingListItemState.ACT,
        lastBuyDate: null,
        previousPurchasesCount: 0,
        productId: searchItem.id,
        name: searchItem.name,
        subproducts:[],
        category:null,
        group: searchItem.group ? searchItem.group : false,
        bestOffer: searchItem.bestOffer ? searchItem.bestOffer : null,
        shopIds: []
    }
    try{
        const response = await RequestQueue.postJson(`${REST_URL}/shoppingList`, newShoppingListItem)
        const newUser = {...globalStateCopy.user, blacklistedProducts: globalStateCopy.user.blacklistedProducts.filter(id=>response?.data?.id !== id)}
        const newList = [...globalStateCopy.user.shoppingListItems, response.data];
        updateStateShoppingListItems(newUser, setUser, newList, true);
        setNotification({text:"Položka přidána",type:"SUCCESS",show:true})
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function toggleBlacklistedProduct(productId:number, newState:boolean, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    //TODO: cannot blacklist item that is in sl - backend 
    if (newState && globalStateCopy.user.shoppingListItems.find(sli=>sli.state!==ShoppingListItemState.HID && sli.productId===productId)){
        setNotification({text: "Nelze zakázat položku která je přímo v nákupním seznamu. Nejprve ji odstraňte.", type: "ERROR", show: true})
        return;
    }
    let newUser;
    let newList;
    
    try{
        if (newState){  //add to list
            await RequestQueue.postRequest(`${REST_URL}/users/blacklist/${productId}`);
            newList = [...globalStateCopy.user.blacklistedProducts, productId];
        }else{  //remove from list
            await RequestQueue.deleteRequest(`${REST_URL}/users/blacklist/${productId}`);
            newList = globalStateCopy.user.blacklistedProducts.filter(id => (id !== productId));
        }
        newUser = {...globalStateCopy.user};
        newUser.blacklistedProducts = newList
        setUser(newUser);
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function toggleShoppingItemState(shoppingItemId:number, newState:boolean, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        if (newState){  //check
            await RequestQueue.putRequest(`${REST_URL}/shoppingItem/${shoppingItemId}/check`);
        }else{  //uncheck
            await RequestQueue.putRequest(`${REST_URL}/shoppingItem/${shoppingItemId}/uncheck`);
        }
        const newUser = {...globalStateCopy.user, currentShopping: {...globalStateCopy.user.currentShopping}};

        let editedItem = {...globalStateCopy.user.currentShopping.shoppingItems.find(shoppingItem => shoppingItem.id === shoppingItemId)} as ShoppingItemDTO;
        editedItem.confirmed = newState;
        let newList = globalStateCopy.user.currentShopping.shoppingItems.filter(shoppingItem => shoppingItem.id !== shoppingItemId);
        newList.push(editedItem);
        newUser.currentShopping.shoppingItems = newList
        updateShopping(newUser.currentShopping)
        setUser(newUser);
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}










export async function logout(setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        await RequestQueue.rawRequest({
            url: `${LOGIN_URL}/j_spring_security_logout`, 
            options: {credentials: 'include'}})
        setUser(defaultUserState)
    }catch(e){
        displayError(e, "Chyba při komunikaci se serverem. ", setNotification);
    }
}

export async function loginRequest(credentials: LoginCredentials, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any) {
    const searchParams = new URLSearchParams({username: credentials.email,password: credentials.password});
    try{
        const resp = await axios.post(`${LOGIN_URL}/j_spring_security_check`, searchParams)
        if (resp.data.loggedIn){
            loadUser(setUser, setNotification, false);
        }else{
            setNotification({text:"Nesprávné údaje.",type:"ERROR",show:true})
        }
        if (document.location.pathname === "/optimizedShoppings"){
            document.location.pathname = "/shoppingList"
        }
    }catch(e){
        displayError(e, "Chyba při přihlašování. ", setNotification);
    }
}

export async function registerRequest(userInfo: RegisterCredentials, setUser: (user: User)=>any, setIssues: (user: string[])=>any, setNotification: (n: NotificationState)=>any) {
    const loginCredentials: LoginCredentials = {email: userInfo.email, password: userInfo.password}
    try{
        await RequestQueue.postJson(`${REST_URL}/users`, userInfo)
        loginRequest(loginCredentials, setUser, setNotification)
    }catch(e){
        displayError(e, "Chyba při registraci. ", setNotification);
    }
}

export async function resetPassword(credentials: ResetPasswordDTO, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any, navigate: (n: string)=>any){
    const loginCredentials: LoginCredentials = {email: credentials.email, password: credentials.password}
    try{
        await RequestQueue.postJson(`${REST_URL}/users/resetPassword`, credentials)
        loginRequest(loginCredentials, setUser, setNotification);
        navigate(MyRoutes.shoppingList.path)
        setNotification({text:"Heslo bylo úspěšně změněno",type:"SUCCESS",show:true})
    }catch(e){
        displayError(e, "Chyba ověření. ", setNotification);
    }
}

export async function changePassword(credentials: ChangePasswordDTO, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    const loginCredentials: LoginCredentials = {email: credentials.email, password: credentials.password}
    try{
        await RequestQueue.postJson(`${REST_URL}/users/changePassword`, credentials)
        loginRequest(loginCredentials, setUser, setNotification);
    }catch(e){
        displayError(e, "Chyba ověření. ", setNotification);
    }
}

export async function requestPasswordResetEmail(email: string, setNotification: (n: NotificationState)=>any){
    try{
        await RequestQueue.getJson(encodeURI(`${REST_URL}/users/requestPasswordResetEmail?email=${email}`))
        setNotification({text: "Na váš email byl odeslán odkaz pro změnu hesla.", type: "SUCCESS", show:true})
    }catch(e){
        displayError(e, "Chyba při požadavku na odeslání emailu. ", setNotification);
    }
}

export async function requestEmailVerification(email: string, setNotification: (n: NotificationState)=>any){
    try{
        await RequestQueue.getJson(encodeURI(`${REST_URL}/users/requestVerificationEmail?email=${email}`))
        setNotification({text: "Na váš email byl odeslán odkaz pro ověření.", type: "SUCCESS", show:true})
    }catch(e){
        displayError(e, "Chyba při požadavku na odeslání emailu. ", setNotification);
    }
}

export function userDTOToUser(updatedUser: UserDTO, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    if (updatedUser.role === "TO_VERIFY"){
        setUser({...defaultUserState, state: "login"});
        setNotification({text:"Pro přihlášení je potřeba nejprve ověřit emailovou adresu",type:"WARNING",show:true})
        return;
    }
    const newUser:User = {...updatedUser, state: "logged"}
    if (!newUser.currentShopping) newUser.currentShopping = defaultCurrentShopping;
    newUser.shoppingListItems.sort(compareShoppingListItems);
    newUser.shoppingListItems.forEach(sli=>sli.subproducts.sort(compareSubproducts))
    newUser.shoppings.sort(compareShoppingsByDate);
    newUser.shoppings.forEach(s=>updateShopping(s, true))
    updateShopping(newUser.currentShopping, true);
    setUser(newUser);
    //setNotification({text:"Přihlášení proběhlo úspěšně",type:"SUCCESS",show:true});
}

export async function loadUser(setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any, noRetries = true){
    try{
        const resp = await RequestQueue.getJson(`${REST_URL}/users/current`, {noRetries: noRetries})
        userDTOToUser(resp.data, setUser, setNotification)
    }catch(e){
        //displayError(e, "Nepřihlášen. ", setNotification);
        setUser({...defaultUserState, state: "login"})
    }
}

export function updateShopping(shopping: ShoppingDTO, fromServer:boolean = false){
    shopping.shoppingItems.sort(compareShoppingItems);
    if (fromServer){
        shopping.shoppingItems.forEach(si=>{
            if (!si.originalAmount) si.originalAmount = si.amount;
            if (si.type === "MISS" || si.type === "NOTE" && !si.originalAmountUnit) si.originalAmountUnit = si.amountUnit;
            if (si.type === "EXTRA" || si.type === "REQ" && !si.originalCost) si.originalCost = si.cost;
        });
    }
    shopping.notes = shopping.shoppingItems.filter(si=>si.type==="NOTE") as MissingItemDTO[];
    shopping.products = shopping.shoppingItems.filter(si=>si.type!=="NOTE");
    shopping.missingItems = shopping.products.filter(si=>si.type=="MISS").length;
}







export async function loadShops(setShops: (shops: ShopDTO[])=>any, setNotification: (n: NotificationState)=>any){
    try{
        const resp = await RequestQueue.getJson(`${REST_URL}/shops`)
        setShops(resp.data);
    }catch(e){
        displayError(e, "Chyba při komunikaci se serverem. ", setNotification);
    }
}

export async function toggleShop(shopId:number, newState:boolean, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        let newList;
        if (newState){  //add to list
            await RequestQueue.postRequest(`${REST_URL}/users/addShop/${shopId}`);
            newList = [...globalStateCopy.user.shops, shopId];
        }else{  //remove from list
            //newList = user.shops.filter(id => (id !== shopId));
            await RequestQueue.deleteRequest(`${REST_URL}/users/removeShop/${shopId}`);
            newList = globalStateCopy.user.shops.filter(id => (id !== shopId));
        }
        const newUser = {...globalStateCopy.user};
        newUser.shops = newList
        setUser(newUser);
        setNotification({text:"Změna uložena",type:"INFO",show:true})
        globalStateCopy.shoppingListUpdateNeeded = true;
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function toggleMembership(membershipId:number, newState:boolean, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        let newList;
        if (newState){  //add to list
            await RequestQueue.postRequest(`${REST_URL}/users/addMembership/${membershipId}`);
            newList = [...globalStateCopy.user.memberships, membershipId];
        }else{  //remove from list
            await RequestQueue.deleteRequest(`${REST_URL}/users/removeMembership/${membershipId}`);
            newList = globalStateCopy.user.memberships.filter(id => (id !== membershipId));
        }
        const newUser = {...globalStateCopy.user};
        newList.sort()
        newUser.memberships = newList
        setUser(newUser);
        setNotification({text:"Změna uložena",type:"INFO",show:true})
        //globalStateCopy.shoppingListUpdateNeeded = true; only if membership is used to filter offers
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function setMaxShopCount(newValue: number, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        await RequestQueue.putRequest(`${REST_URL}/users/setMaxShopCount/${newValue}`)
        const newUser = {...globalStateCopy.user};
        newUser.maxShopCount = newValue;
        setUser(newUser);
        setNotification({text:"Změna uložena",type:"INFO",show:true})
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function optimize(setAvailableShoppings: (s: ShoppingDTO[])=>any, setNotification: (n: NotificationState)=>any){
    try{
        const resp = await RequestQueue.postJson(`${REST_URL}/optimize`, null)
        resp.data.forEach((s: ShoppingDTO)=>updateShopping(s, true))
        resp.data.sort(compareShoppings) 
        resp.data.forEach((item: ShoppingDTO, index: number)=>item.temporalId = index)     
        setAvailableShoppings(resp.data)
    }catch(e){
        displayError(e, "Chyba při optimalizaci. ", setNotification);
    }
}

export function updateShoppings(newCurrentShopping: ShoppingDTO, user:User, setUser: (user: User)=>any, updateUser: boolean){
    const newUser = {...user};
    newUser.shoppings = user.shoppings.filter(shopping=>shopping.id!==newCurrentShopping.id)
    if (newUser.shoppings.length != user.shoppings.length) 
        newCurrentShopping = {...newCurrentShopping};
    
    //add old current shopping in users shoppings, set it as ABORTED
    const oldCurrentShopping = {...user.currentShopping};
    if (oldCurrentShopping){
        oldCurrentShopping.state = ShoppingState.ABORTED;
        newUser.shoppings.push(oldCurrentShopping);
    }
    //set current shopping
    newUser.currentShopping = newCurrentShopping;
    updateShopping(newUser.currentShopping);
    newUser.shoppings.sort(compareShoppingsByDate);
    if (updateUser) setUser(newUser);
    return newUser;
}

export async function startShopping(shopping:ShoppingDTO, setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        const resp = await RequestQueue.postJson(`${REST_URL}/shopping/start`, shopping)
        updateShoppings(resp.data, globalStateCopy.user, setUser, true)
    }catch(e){
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function finishShopping(setUser: (user: User)=>any, setNotification: (n: NotificationState)=>any){
    try{
        const resp = await RequestQueue.putRequest(`${REST_URL}/shopping/${globalStateCopy.user.currentShopping.id}/finish`);
        const newUser = updateShoppings({...defaultCurrentShopping}, globalStateCopy.user, setUser, false);
        updateStateShoppingListItems(newUser, setUser, resp.data, true);
    }catch(e){
        console.log(e)
        displayError(e, "Chyba při ukládání změny na server. ", setNotification);
    }
}

export async function getProductById(productId:number|null, setSearchItem:(p:ProductDTO)=>any, setNotification: (n: NotificationState)=>any){
    try{
        const resp = await RequestQueue.getJson(`${REST_URL}/products/${productId}`)
        setSearchItem(resp.data)
    }catch(e){
        displayError(e, "Chyba při načítání detailu produktu. ", setNotification);
    }
}


export async function getRecommendations(setRecommendations:(p:SearchItemDTO[])=>any){
    try{
        const resp = await RequestQueue.getJson(`${REST_URL}/products/getRecommendations`)
        setRecommendations(resp.data)
    }catch(e){
        //displayError(e, "Chyba při načítání detailu produktu. ", setNotification);
    }
}