import {  useState } from "react";
import { requestEmailVerification, requestPasswordResetEmail } from "../actions";
import Modal from "./Modal";
import { displayedPopupState, notificationState } from "../state";
import { useRecoilState } from "recoil";
import { FaTimes } from "react-icons/fa";
import { LoadingSubmitButton } from "./common";



export default function InsertEmailPrompt(){
    const [email, setEmail] = useState<string>("");
    const [issues, setIssues] = useState<string[]>([]);
    const [notification, setNotification] = useRecoilState(notificationState)
    const [displayedPopup, setDisplayedPopup] = useRecoilState(displayedPopupState)


    function _onSubmit(){
        let messages = [];
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)){
            messages.push("Email není ve správném formátu.")
        }
        setIssues(messages)
        if (messages.length == 0){
            if (displayedPopup === "insertEmail") requestPasswordResetEmail(email, setNotification)
            if (displayedPopup === "verifyEmail") requestEmailVerification(email, setNotification)
            setDisplayedPopup("");
        }
    }

    return (
        <Modal isOpen={displayedPopup==="insertEmail" || displayedPopup==="verifyEmail"}>
            <FaTimes className="topRight icon" onClick={()=>setDisplayedPopup("")}/>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Přihlášení</h3>
            <form method="post" target="" className="space-y-6">
                <div>
                    <label htmlFor="emailIEP" className="formLabel">Email</label>
                    <input id="emailIEP" type="text" name='email' placeholder="petr.novak@seznam.cz" onChange={(e)=>setEmail(e.target.value)} value={email} className="formInput"/>
                </div>
                

                <ul className={`bg-red-200 p-2 rounded-md text-red-600 ${issues.length==0?"hidden":""}`}>
                    {issues.map(issue=>(<li>{issue}</li>))}
                </ul>
                
                <LoadingSubmitButton text="Potvrdit" onClick={e=>{e.preventDefault();_onSubmit()}}/>
            </form>
        </Modal>
    );
}
