import {useRecoilState} from 'recoil';
import { Toggle, ToggleWithState } from '../components/common';
import { User } from '../dtos';
import { useUser } from '../hooks';
import { displayedPopupState, userState } from "../state";

export default function SettingsPage() {
    const [displayedPopup, setDisplayedPopup] = useRecoilState(displayedPopupState)
    const [user,setUser] = useUser()

    function changeDarkMode(isDarkMode: boolean){
        if (isDarkMode){
            localStorage.theme = 'dark'
            document.documentElement.classList.add('dark')
        }else{
            localStorage.theme = 'light'
            document.documentElement.classList.remove('dark')
        }
    }

    function refreshDb(){
        fetch("/rest/adminTools/refreshProductDb", {method: "PUT"})
    }

    return (
        <div className='page p-5 flex-col gap-3'>
            <div className='flex gap-5 w-full'>
                <div className='formLabel'>Dark mode</div>
                <ToggleWithState init={localStorage.theme==='dark'} onChange={changeDarkMode}/>
            </div>
            <button className="blueButton" onClick={()=>setDisplayedPopup("changePassword")}>Změnit heslo</button>
            {user.role === 'ADMIN' ? <button className="blueButton" onClick={refreshDb}>Refresh db</button>:""}
        </div>
    );
}