import { MissingItemDTO, ShoppingItemDTO } from "../dtos";
import { mapUnitsToHumanReadableForm, unitsToString } from "../tools";

type Props={
    data: MissingItemDTO,
    onStateUpdate: (id: number, state: boolean) => any,
    onAmountUpdate?: (updated: ShoppingItemDTO) => any,
    showCheckbox: boolean
}
export default function MissingItem(props: Props) {
    let item = props.data;

    function _changeState(e: React.ChangeEvent<HTMLInputElement>){
        props.onStateUpdate(item.id, e.target.checked);
    }

    function _onAmountUpdate(e: React.ChangeEvent<HTMLInputElement>){
        const newData = {...props.data} as ShoppingItemDTO
        newData.amount = parseFloat(e.target.value);
        if (props.onAmountUpdate) props.onAmountUpdate(newData);
    }

    function showAmount(){
        if (!props.onAmountUpdate) return unitsToString(item.amount, item.amountUnit);
        return (
            <>
                <input className="formInput w-20" type="number" name="amount" min={0} value={item.amount} onClick={e=>e.stopPropagation()} onChange={_onAmountUpdate}/>
                {item.amountUnit ? mapUnitsToHumanReadableForm(item.amountUnit) : null}
            </>
        )
    }

    return (
        <div className={"subItem hover:cursor-pointer transition-all " + (item.confirmed ? "opacity-50" : "opacity-100")} onClick={()=>props.onStateUpdate(item.id, !item.confirmed)}>
            <div className={"w-full flex gap-2"}> 
                <div className={"grid-cols-3 lg:grid-cols-4 grid font-bold capitalize gap-2 items-center w-full justify-between"}>
                    <div className="col-span-2">
                        {item.name}
                    </div>
                    <div className="font-normal text-sm flex items-center gap-2">
                        {showAmount()}
                    </div>
                </div>  
                {!props.showCheckbox ? "" : (
                    <div className="lg:w-1/6 flex justify-end">
                        <input className="roundCheckbox" type="checkbox" checked={item.confirmed} onClick={e=>e.stopPropagation()} onChange={_changeState}/>
                    </div>
                )}          
            </div>
        </div>
        
    
    );
}