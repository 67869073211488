import { useState } from "react";
import { useRecoilState } from "recoil";
import { toggleShop, toggleMembership } from "../actions";
import { MembershipDTO, ShopDTO } from "../dtos";
import { useUser } from "../hooks";
import { notificationState, userState } from "../state";
import Collapsable from "./Collapsable";
import { ShopIcon } from "./ShopWithPrice";

type Props = {
    shop: ShopDTO
}

export default function ShopItem(props: Props) {
    const [user, setUser] = useUser();
    const [notification, setNotification] = useRecoilState(notificationState);
    let item = props.shop;

    function _toggleShop(shopId: number, newState: boolean){
        toggleShop(shopId, newState, setUser, setNotification);
    }

    function _toggleMembership(membershipId: number, newState: boolean){
        toggleMembership(membershipId, newState, setUser, setNotification);
    }

    return (
        <div className={"card"}>
            <div className="hover:cursor-pointer flex justify-between items-center px-6" onClick={()=>_toggleShop(item.id, user.shops.find((id: number)=>id===item.id)===undefined)}>
                <ShopIcon shopName={item.name} color="bg-white border border-slate-400 border-[2px]"/>
                <div className="capitalize">
                    {item.name}
                </div>
                <input className="roundCheckbox" type="checkbox" checked={user.shops.find((id: number)=>id===item.id)!==undefined} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>_toggleShop(item.id, e.target.checked)}/>
            </div>
            <Collapsable expanded={item.memberships.length>0}>
                {item.memberships.map((membership: MembershipDTO)=>
                    <div className="subItem flex justify-between">
                        <div className="capitalize">{membership.name}</div>
                        <input className="roundCheckbox" type="checkbox" checked={user.memberships.find((id:number)=>id===membership.id)!==undefined} onChange={e=>_toggleMembership(membership.id, e.target.checked)}/>
                    </div>
                )}
            </Collapsable>
        </div>
    
    );
}