import ShopItem from "../components/ShopItem";
import { optimize, setMaxShopCount } from "../actions";
import {useRecoilState, useRecoilValue} from 'recoil';
import { availableShoppingsState, availableShopsState, notificationState, userState } from "../state";
import MyRoutes from "../routing/Routes";
import { useNavigate } from "react-router-dom";
import { ButtonFront } from "../components/common";
import { ShopDTO, User } from "../dtos";
import { useEffect, useState } from "react";
import { useUser } from "../hooks";

export default function ParametersPage() {
    const shops = useRecoilValue(availableShopsState);
    const [user,setUser] = useUser();
    const [availableShoppings, setAvailableShoppings] = useRecoilState(availableShoppingsState);
    const navigate = useNavigate();
    const [notification, setNotification] = useRecoilState(notificationState);
    const [maxShopCountInput, setMaxShopCountInput] = useState(1);

    useEffect(()=>setMaxShopCountInput(user.maxShopCount),[user.maxShopCount]);

    function _setMaxShopCount(){
        const newValue = parseInt((document.getElementById("maxCost") as HTMLInputElement).value);
        setMaxShopCount(newValue, setUser, setNotification);
    }


    function _optimize(){
        if (user.shoppingListItems.filter(s=>s.state==="ACT"||s.state==="OS").length === 0){
            setNotification({text: "Pokud chcete spustit optimalizaci nejprve vložte něco do vašeho nákupního seznamu.", type: "ERROR", show: true})
            return;
        }
        if (user.shops.length === 0){
            setNotification({text: "Pokud chcete spustit optimalizaci nejprve povolte alespoň jeden z obchodů.", type: "ERROR", show: true})
            return;
        }
        optimize(setAvailableShoppings, setNotification);
        navigate(MyRoutes.optimizedShoppings.path);
    }

    return (
        <div className={"page"}>
            <div className="grid grid-cols-3 gap-4 p-4 items-center">
                <label htmlFor="maxCost" className="formLabel text-center">Maximální počet navštívených obchodů:</label>
                <input id="maxCost" className="formInput" type="number" min="1" max="5" 
                    onChange={e=>setMaxShopCountInput(parseInt(e.target.value))} 
                    value={maxShopCountInput}/>
                <button className="blueButton" onClick={_setMaxShopCount}>Potvrdit</button>
            </div>
                
            
            <div className="listWrapper">
                {shops.map((shop: ShopDTO,i:number)=>{
                    //shop.checked = user.shops.find(id=>id===shop.id);
                    //shop.memberships.forEach(mem)
                    return <ShopItem key={i} shop={shop}/>
                })}
            </div>
            <ButtonFront onClick={_optimize} text="Optimalizovat"/>
        </div>
    );
}