import { useState } from "react";
import { FaCheck, FaPercent, FaTimes } from "react-icons/fa";
import { ShoppingListItemState } from "../dtos";

type ToggleWithStateProps = {
    init: boolean,
    onChange: (a:boolean)=>any
}
export function ToggleWithState(props: ToggleWithStateProps){
    const [active, setActive] = useState(props.init);
    function update(){
        props.onChange(!active)
        setActive(!active);
    }
    return (
        <Toggle checked={active} onChange={update}/>
    );
}

type ToggleProps = {
    checked: boolean,
    color?: string,
    onChange: (a:boolean)=>any
}
export function Toggle(props: ToggleProps){
    function update(){
        props.onChange(props.checked)
    }
    let color = props.color || "bg-blue-600"
    return (
        <div className={"h-6 w-11 rounded-full cursor-pointer bg-slate-200"} onClick={update}>
            <div className={"h-6 w-6 rounded-full  transition-all " + (!props.checked?"bg-slate-400 ml-0":`ml-5 ${color}`)}></div>
        </div>
    );
}


type LoadingProps = {
    className?: string,
    id?: string
}
export function Loading(props: LoadingProps){
    return (
        <div className={props.className} id={props.id}>
            <div className="inline-block h-7 w-7 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"/>
            </div>
        </div>
    );
}

type LoadingSubmitButtonProps = {
    text: string,
    onClick: (a: any)=>any
}
export function LoadingSubmitButton(props: LoadingSubmitButtonProps){
    return (
        <button type="submit" onClick={props.onClick} className="flex items-center justify-center gap-3 blueButton">
            {props.text}
            <Loading className={"transition-all duration-500 hidden queueLoading"}/>
        </button>
    )
}

type ButtonFrontProps = {
    text: string,
    onClick?: (a: any)=>any,
    front?: boolean
}
export function ButtonFront(props: ButtonFrontProps){
    return (
        <>
            <button className={`${props.front?"z-20 sticky mt-10 bottom-10 mb-10 ": ""} px-6 bg-blue-700  text-slate-100 p-3 rounded-2xl text-2xl`} onClick={props.onClick}>
                {props.text}
            </button>
        </>
        
    );
}

type TristateSwitchProps = {
    state: ShoppingListItemState,
    changeState: (a: ShoppingListItemState)=>any
}
export function TristateSwitch({state, changeState}: TristateSwitchProps){
    //const [state, setState] = useState(props.init);
    function update(e: React.MouseEvent<any>, newState: ShoppingListItemState){
        e.stopPropagation();
        changeState(newState)
    }

    function showDot(){
        const color = state==="ACT" ? "bg-green-500" : (state==="OS" ? "bg-yellow-500" : "bg-red-600");
        const position = state==="ACT" ? "left-0" : (state==="OS" ? "left-[1.8rem]" : "left-[3.55rem]");
        const nextState = state==="ACT" ? ShoppingListItemState.OS : (state==="OS" ? ShoppingListItemState.NACT : ShoppingListItemState.ACT)
        return (
            <div className={`flex items-center justify-center z-10 h-[1.8rem] w-7 rounded-full transition-all absolute top-[0.25px] ${color} ${position} text-white`} onClick={e=>update(e,nextState)}>
                {state==="ACT" ? <FaCheck className="w-5 h-5"/> : (state==="OS" ? <FaPercent className="w-4 h-4"/> : <FaTimes className="w-5 h-5"/>)}
            </div>
        );
    }

    return (
        <div className="p-1 ml-auto items-center relative h-8 w-[5.5rem] rounded-full cursor-pointer border-2 border-slate-300 dark:border-slate-600 flex gap-3" onClick={e=>e.stopPropagation()}>
            <FaCheck className="w-5 h-5 text-green-500" onClick={e=>update(e,ShoppingListItemState.ACT)}/>
            <FaPercent className="w-4 h-4 text-yellow-500" onClick={e=>update(e,ShoppingListItemState.OS)}/>
            <FaTimes className="w-5 h-5 text-red-600" onClick={e=>update(e,ShoppingListItemState.NACT)}/>
            {showDot()}
        </div>
    );
}