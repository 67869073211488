export const mockStartShopping = {
    "id": 34,
    "shopIds": [
        1
    ],
    "timestamp": "2023-03-04T22:18:44.2488371",
    "state": "ONGOING",
    "shoppingItems": [
        {
            "id": 19,
            "amount": 1.0,
            "confirmed": false,
            "name": "moz",
            "amountUnit": "KS",
            "type": "NOTE"
        },
        {
            "id": 20,
            "amount": 125.0,
            "confirmed": false,
            "name": "mozzarella",
            "amountUnit": "G",
            "productId": 428,
            "type": "MISS"
        },
        {
            "id": 21,
            "offerId": 117,
            "amount": 0.5,
            "confirmed": false,
            "cost": 29.95,
            "name": "paprika bílá",
            "brand": "",
            "packageSizeUnit": "KG",
            "category": "ovoce-a-zelenina/zelenina/rajcata-a-papriky",
            "shopName": "tesco",
            "type": "REQ"
        },
        {
            "id": 22,
            "offerId": 116,
            "amount": 1.0,
            "confirmed": false,
            "cost": 29.9,
            "name": "tesco rajčata cherry 250g",
            "brand": "tesco",
            "packageSizeUnit": "KG",
            "packageSize": 0.25,
            "category": "ovoce-a-zelenina/zelenina/rajcata-a-papriky",
            "shopName": "tesco",
            "type": "REQ"
        },
        {
            "id": 23,
            "offerId": 129,
            "amount": 0.5,
            "confirmed": false,
            "cost": 39.95,
            "name": "česká rajčata na stonku",
            "brand": "",
            "packageSizeUnit": "KG",
            "category": "ovoce-a-zelenina/zelenina/rajcata-a-papriky",
            "shopName": "tesco",
            "type": "REQ"
        }
    ],
    "extraItems": [],
    "totalCost": 169.70001,
    "extraCost": 0.0
}