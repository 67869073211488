const MyRoutes = {
    home: {name: 'home', path: '/'},
    shoppingList: {name: 'shoppingList', path: '/shoppingList'},
    optimizedShoppings: {name: 'optimizedShoppings', path: '/optimizedShoppings'},
    shoppings: {name: 'shoppings', path: '/shoppings'},
    shopping: {name: 'shopping', path: '/shopping/:shoppingId'},
    parameters: {name: 'parameters', path: '/parameters'},
    settings: {name: 'settings', path: '/settings'},
    currentShopping: {name: 'currentShopping', path: '/currentShopping'},
    productSearch: {name: 'productSearch', path: '/productSearch'},
    resetPassword: {name: 'resetPassword', path: '/resetPassword'},
};

export default MyRoutes;
