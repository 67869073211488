import { useRecoilState, useRecoilValue } from "recoil";
import { availableShoppingsState } from "../state";
import Shopping from "../components/Shopping";
import { ProductShoppingItemDTO, ShoppingDTO, ShoppingItemDTO } from "../dtos";
import { compareShoppings } from "../tools";
import { updateShopping } from "../actions";

export default function AvailableShoppingsPage() {
    const [availableShoppings, setAvailableShoppings] = useRecoilState<ShoppingDTO[]>(availableShoppingsState);

    function _addItemToShopping(newItem: ProductShoppingItemDTO, temporalShoppingId: number){
        let editedShoppingIndex = availableShoppings.findIndex(item => item.temporalId === temporalShoppingId) as number;
        let editedShopping = availableShoppings[editedShoppingIndex] as ShoppingDTO;
        let newShoppings = [...availableShoppings];
        
        editedShopping = {
            ...editedShopping, 
            extraCost: editedShopping.extraCost+newItem.cost, 
            shoppingItems: [...editedShopping.shoppingItems]}
        editedShopping.shoppingItems.push(newItem);

        updateShopping(editedShopping);
        newShoppings[editedShoppingIndex] = editedShopping;
        setAvailableShoppings(newShoppings);
    }

    function _updateShoppingItem(updatedItem: ShoppingItemDTO, temporalShoppingId: number, deleteOnZero: boolean){     
        let editedShoppingIndex = availableShoppings.findIndex(item => item.temporalId === temporalShoppingId) as number;
        let editedShopping = {...availableShoppings[editedShoppingIndex]} as ShoppingDTO;
        let newShoppings = [...availableShoppings];

        if (updatedItem.type === "EXTRA") editedShopping.extraCost -= updatedItem.cost;
        if (updatedItem.type === "REQ") editedShopping.totalCost -= updatedItem.cost;

        if (updatedItem.type === "EXTRA" || updatedItem.type === "REQ") 
            updatedItem.cost = updatedItem.originalCost / updatedItem.originalAmount * updatedItem.amount;

        editedShopping.shoppingItems = editedShopping.shoppingItems.filter(item=>{
            if (item.id && item.id===updatedItem.id) return false;
            if (item.productId && item.productId===updatedItem.productId) return false;
            return true;
        })
        if (updatedItem.type === "EXTRA") editedShopping.extraCost += updatedItem.cost;
        if (updatedItem.type === "REQ") editedShopping.totalCost += updatedItem.cost;
        if (updatedItem.amount !== 0 || !deleteOnZero){
            editedShopping.shoppingItems.push(updatedItem);
        }
        
        updateShopping(editedShopping);
        newShoppings[editedShoppingIndex] = editedShopping;
        setAvailableShoppings(newShoppings);
    }

    return (
        <div className={"page"}>
            <div className="listWrapper">
                {availableShoppings.map((item: ShoppingDTO, i: number)=>{
                    return <Shopping showSales={true} key={i} shopping={item} ongoing={false} addItemToShopping={_addItemToShopping} updateShoppingItem={_updateShoppingItem}/>
                })}
            </div>
        </div>
    );
}