
type Props = {
    children: JSX.Element | JSX.Element[],
    expanded: boolean,
    fullView?: boolean
}

export default function Collapsable(props : Props) {
    if (props.fullView) return <>{props.children}</>
    return (
        <div className={"scrollbar-hide overflow-y-scroll transition-[max-height] duration-300 " + (props.expanded ? `max-h-96 ease-in` : "max-h-0 ease-out")}>
            <div className={props.expanded?"scale-100 transition delay-100 duration-[0s]":"scale-0 hidden"}>
                <div className="p-2 pr-4 flex flex-col gap-2">
                    {props.expanded ? props.children : ""}
                </div>
            </div>
        </div>
    );
}
