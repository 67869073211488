import { useRecoilState, useRecoilValue } from "recoil";
import { availableShopsState, notificationState, recommendationsState, userState } from "../state";
import { toggleShoppingItemState } from "../actions";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ShoppingItem from "./ShoppingItem";
import { MissingItemDTO, OfferDTO, ProductShoppingItemDTO, SearchItemDTO, ShopDTO, ShoppingDTO, ShoppingItemDTO, User } from "../dtos";
import MissingItem from "./MissingItem";
import {ShopIcon} from "./ShopWithPrice";
import Collapsable from "./Collapsable";
import { mapRecommendationToShoppingItem, rounded, stringDateToDayAndMonth } from "../tools";
import { useUser } from "../hooks";

type Props={
    shopping: ShoppingDTO,
    fullView: boolean,
    showSales?: boolean
}
export default function OngoingShopping(props: Props) {
    const [user, setUser] = useUser();
    const navigate = useNavigate();
    const shops = useRecoilValue(availableShopsState);
    const [expanded, setExpanded] = useState(false);
    const [notification, setNotification] = useRecoilState(notificationState);
    const recommendations = useRecoilValue<SearchItemDTO[]>(recommendationsState);


    const gridSize = props.fullView ? "grid-cols-3" : "grid-cols-4";

    let missingItems = props.shopping.products.filter(item=>item.type=="MISS").concat(props.shopping.notes);
                            missingItems.sort((a,b)=>a.confirmed===b.confirmed ? 0 : (a.confirmed ? 1 : -1));

    return (
        <div className={`card ${props.fullView ? "flex-grow overflow-scroll scrollbar-hide":""}`}>
            <div className={"grid w-full gap-2 hover:cursor-pointer items-center "+gridSize} onClick={()=>setExpanded(!expanded)}>
                <div className="flex col-span-2 gap-2 items-center flex-wrap">
                    Obchody: 
                    {props.shopping.shopIds.map(shopId=>{
                        const shopName:string = shops?.find((s: ShopDTO)=>s.id===shopId)?.name
                        return <ShopIcon key={shopName} shopName={shopName} color="bg-white border border-slate-400 border-[2px]"/>
                    })}
                </div>
                <div>Cena: {rounded(props.shopping.totalCost,0)} Kč</div>
                {!props.fullView ? <div>{stringDateToDayAndMonth(props.shopping.timestamp, true)}</div> : null}
            </div>
            <Collapsable expanded={expanded} fullView={props.fullView}>
                <div className={`pt-4 ${props.shopping.products.length === 0 ? "hidden" : ""}`}>
                    <div className="text-lg">Položky:</div>
                    <div className="flex flex-col pt-2">
                        {props.shopping.products.filter(item=>item.type!="MISS").map((item, i)=>{
                            return <ShoppingItem key={i} data={item as ProductShoppingItemDTO} 
                                onStateUpdate={(id,state)=>toggleShoppingItemState(id, state, setUser, setNotification)} 
                                showCheckbox={props.fullView}/>
                        })}
                    </div>
                </div>
                <div className={`pt-4 ${props.shopping.notes.length === 0 && missingItems.length === 0 ? "hidden" : ""}`}>
                    <div className="text-lg">Poznámky:</div>
                    <div className="flex flex-col pt-2">
                        {
                            missingItems.map((item, i)=>{            
                                return <MissingItem key={i} data={item as MissingItemDTO} 
                                        onStateUpdate={(id,state)=>toggleShoppingItemState(id, state, setUser, setNotification)} 
                                        showCheckbox={props.fullView}/>
                            }
                        )}
                    </div>
                </div>
                <div className={`pt-4 ${props.showSales?"":"hidden"}`}>
                    <div className="text-lg">Zboží v akci:</div>
                    <div className="flex flex-col pt-2">
                        {recommendations
                            .filter((item: SearchItemDTO)=>
                                props.shopping.shopIds.includes((item.bestOffer as OfferDTO).shopId)&&
                                props.shopping.products.find(p=>p.productId === item.id)===undefined)
                            .slice(0,50)
                            .map((item, i)=>{
                                return <ShoppingItem key={i} data={mapRecommendationToShoppingItem(item, shops)} 
                                    onStateUpdate={()=>{}} 
                                    showCheckbox={false}/>
                        })}
                    </div>
                </div>
            </Collapsable>
        </div>
    );
}
//

