import { useRecoilState, useRecoilValue } from "recoil";
import { availableShoppingsState, userState } from "../state";
import Shopping from "../components/Shopping";
import { ShoppingDTO, User } from "../dtos";
import OngoingShopping from "../components/OngoingShopping";
import { useUser } from "../hooks";

export default function ShoppingHistoryPage() {
    const [user, setUser] = useUser();

    return (
        <div className={"page"}>
            <div className="listWrapper">
                {user.shoppings?.map((item: ShoppingDTO, i: number)=>{
                    return <OngoingShopping key={i} shopping={item} fullView={false}/>
                })}
            </div>
        </div>
    );
}