import { useRecoilState, useRecoilValue } from "recoil";
import AutocompletedForm from "../components/AutocompletedForm";
import { notificationState, searchItemState, userState } from "../state";
import SubProduct from "../components/SubProduct";
import {Offers} from "../components/Offers";
import { getProductById } from "../actions";
import { ProductDTO, User } from "../dtos";
import { mapUnitsToHumanReadableForm } from "../tools";
import { useUser } from "../hooks";

export default function SearchPage() {
    const [searchItem, setSearchItem] = useRecoilState<ProductDTO>(searchItemState);
    const [user, setUser] = useUser()
    const [notification, setNotification] = useRecoilState(notificationState);

    return (
        <div className="page">
            <AutocompletedForm source="products/search" placeholder="Produkt" onEnter={c => getProductById(c.id, setSearchItem, setNotification)}/>
            {!searchItem.name ? "" : (
            <div className="listWrapper">
                <div className="card">
                    <div className="grid grid-cols-2 w-full gap-2 lg:grid-cols-4 hover:cursor-pointer items-center">
                        <div className="col-span-2 font-bold capitalize flex gap-2 items-center">
                            <div>
                                {searchItem.name}
                            </div>
                        </div>
                        <div>
                            {searchItem.baseAmount} {mapUnitsToHumanReadableForm(searchItem.baseAmountUnit)}
                        </div>
                    </div>
                    
                    <Offers offers={searchItem.offersByShop}/>
                    <div className="p-2 pr-4 flex flex-col gap-2">
                        {searchItem.subproducts.map(subproduct => {
                            const disabled = user.blacklistedProducts.includes(subproduct.id);
                            return (<SubProduct key={subproduct.id} disabled={disabled} data={subproduct}/>)
                        })}
                    </div>
                </div>
            </div>
            )}
        </div>
        
    );
}
