import {atom,selector} from 'recoil';
import { recoilPersist } from 'recoil-persist'
import { Role, Gender, User, ShoppingDTO, ShoppingState, ShopDTO, ProductDTO, NotificationState, SearchItemDTO } from './dtos';

const { persistAtom } = recoilPersist()



export const defaultCurrentShopping: ShoppingDTO = {
    id: null,
    shopIds: [],
    shoppingItems: [],
	totalCost: 0,
	extraCost: 0,
    state: ShoppingState.ABORTED,
    missingItems: 0,
    notes: [],
    products: [],
}

export const defaultUserState: User = {
    state: "login", 
    email: "",
    firstName: "",
    lastName: "",
    maxShopCount: 1,
    role: Role.USER,
    gender: Gender.MALE,
    birthDate: "",
    town: "",
    notes: [],
    shoppingListItems: [],
    shoppings: [],
    memberships: [],
    shops: [],
    blacklistedProducts: [],
    currentShopping: defaultCurrentShopping
}

const initalNotification: NotificationState = {
    text: "",
    type: "INFO",
    show: false
}

export const userState = atom({
    key: 'userState', // unique ID (with respect to other atoms/selectors)
    default: defaultUserState, // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],
});

export const availableShoppingsState = atom({
    key: 'availableShoppingsState', // unique ID (with respect to other atoms/selectors)
    default: [] as ShoppingDTO[],
    effects_UNSTABLE: [persistAtom],
});

export const searchItemState = atom({
    key: 'searchItemState', // unique ID (with respect to other atoms/selectors)
    default: {} as ProductDTO // default value (aka initial value)
});

export const availableShopsState = atom({
    key: 'availableShopsState', // unique ID (with respect to other atoms/selectors)
    default: [] as ShopDTO[], // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],
});

export const notificationState = atom({
    key: 'notificationState', // unique ID (with respect to other atoms/selectors)
    default: initalNotification, // default value (aka initial value)
});

export const displayedPopupState = atom({
    key: 'displayedPrompt', // unique ID (with respect to other atoms/selectors)
    default: "", // default value (aka initial value)
});

export const recommendationsState = atom({
    key: 'recommendationsState', // unique ID (with respect to other atoms/selectors)
    default: [] as SearchItemDTO[], // default value (aka initial value)
});

export let globalStateCopy = {
    user: {...defaultUserState},
    shoppingListUpdateNeeded: false
};