//import ScanPage from './pages/ScanPage';
import { RecoilRoot } from "recoil";
import PageWrapper from './PageWrapper';

function App() {
  return (
    <div className="body">
      <RecoilRoot>
        <PageWrapper />
      </RecoilRoot>
    </div>
  );
}

export default App;
