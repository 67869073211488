import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { resetPassword } from "../actions";
import Modal from "./Modal";
import { ResetPasswordDTO } from "../dtos";
import { notificationState, userState } from "../state";
import { useUser } from "../hooks";
import { LoadingSubmitButton } from "./common";



export default function ResetPasswordPrompt(){
    const [credentials, setCredentials] = useState<ResetPasswordDTO>({password: "", passwordVerification: "", email: "", uuid: ""});
    const [issues, setIssues] = useState<string[]>([]);
    const [user, setUser] = useUser();
    const [notification, setNotification] = useRecoilState(notificationState);
    const navigate = useNavigate()

    useEffect(()=>{
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        setCredentials({...params, password: "", passwordVerification: ""} as ResetPasswordDTO);
    },[])

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.classList.remove("bg-red-100");
        const newCredentials = {...credentials};
        newCredentials[e.target.name] = e.target.value;
        setCredentials(newCredentials);
    }

    

    function _onSubmit(){
        let messages = [];
        if (!credentials.uuid || !credentials.email){
            messages.push("Odkaz neplatný.")
        }
        if (credentials.password.length < 8 || !/\d/.test(credentials.password) || !/[a-zA-Z]/.test(credentials.password)){
            document.querySelector("#passwordL")?.classList.add("bg-red-100");
            messages.push("Heslo musí mít alespoň 8 znaků a musí obsahovat alespoň jedno číslo a alespoň jedno písmeno.")
        }
        if (credentials.passwordVerification !== credentials.password){
            document.querySelector("#passwordVerificationR")?.classList.add("bg-red-100");
            messages.push("Hesla se neshodují.")
        }
        setIssues(messages)
        if (messages.length == 0){
            resetPassword(credentials, setUser, setNotification, navigate);
        }
    }

    return (
        <Modal isOpen={true}>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Přihlášení</h3>
            <form method="post" target="" className="space-y-6">              
                <div>
                    <label htmlFor="passwordReset" className="formLabel">Nové heslo</label>
                    <input id="passwordReset" type="password" name='password' onChange={_onChange} value={credentials.password} placeholder="••••••••" className="formInput"/>
                </div>
                <div>
                    <label htmlFor="passwordVerificationReset" className="formLabel">Znovu nové heslo</label>
                    <input id="passwordVerificationReset" type="password" name='passwordVerification' onChange={_onChange} value={credentials.passwordVerification} placeholder="••••••••" className="formInput"/>
                </div>
                

                <ul className={`bg-red-200 p-2 rounded-md text-red-600 ${issues.length==0?"hidden":""}`}>
                    {issues.map(issue=>(<li>{issue}</li>))}
                </ul>
                <LoadingSubmitButton text="Potvrdit změnu" onClick={e=>{e.preventDefault();_onSubmit()}}/>
            </form>
        </Modal>
    );
}
