import { useRecoilState, useRecoilValue } from "recoil";
import { availableShopsState, notificationState, recommendationsState, userState } from "../state";
import { startShopping } from "../actions";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import MyRoutes from "../routing/Routes";
import ShoppingItem from "./ShoppingItem";
import Collapsable from "./Collapsable";
import { MissingItemDTO, OfferDTO, ProductShoppingItemDTO, SearchItemDTO, ShopDTO, ShoppingDTO, ShoppingItemDTO, User } from "../dtos";
import MissingItem from "./MissingItem";
import {ShopIcon} from "./ShopWithPrice";
import {Offer} from "./Offers";
import { mapRecommendationToShoppingItem, mapUnitsToHumanReadableForm, rounded, unitsToString } from "../tools";
import { AiFillPlusCircle } from "react-icons/ai";
import { useUser } from "../hooks";

type Props={
    shopping: ShoppingDTO,
    ongoing: boolean,
    showSales?: boolean,
    addItemToShopping: (newItem: ProductShoppingItemDTO, temporalShoppingId: number)=>void
    updateShoppingItem?: (updatedItem: ShoppingItemDTO, temporalShoppingId: number, deleteOnZero: boolean)=>void
}
export default function Shopping(props: Props) {
    const [user, setUser] = useUser();
    const navigate = useNavigate();
    const shops = useRecoilValue(availableShopsState);
    const [expanded, setExpanded] = useState(false);
    const [notification, setNotification] = useRecoilState(notificationState);
    const recommendations = useRecoilValue<SearchItemDTO[]>(recommendationsState);

    function _startShopping(){
        startShopping(props.shopping, setUser, setNotification);
        navigate(MyRoutes.currentShopping.path);
    }

    let _onAmountUpdate = (!props.updateShoppingItem) ? undefined :
        (newData: ShoppingItemDTO, deleteOnZero: boolean = false) => {
            if (props.updateShoppingItem && props.shopping.temporalId !== undefined) props.updateShoppingItem(newData, props.shopping.temporalId, deleteOnZero);
        }
    

    return (
        <div className={"card"}>
            <div className="grid grid-cols-2 w-full gap-2 lg:grid-cols-5 hover:cursor-pointer items-center" onClick={()=>setExpanded(!expanded)}>
                <div className="flex col-span-2 gap-2 items-center">
                    Obchody: 
                    {props.shopping.shopIds.map((shopId,i)=>{
                        const shopName:string = shops?.find((s: ShopDTO)=>s.id===shopId)?.name
                        return <ShopIcon key={i}shopName={shopName} color="bg-white border border-slate-400 border-[2px]"/>
                    })}
                </div>
                <div>Cena: {rounded(props.shopping.totalCost,0)} Kč</div>
                <div>Chybějící položky: {props.shopping.shoppingItems.filter(si=>si.type==="MISS").length}</div>
                <div className="col-span-2 lg:col-span-1">
                    <button className={"blueButton"} onClick={_startShopping}>Začít nakupovat</button>                    
                </div>
            </div>
            <Collapsable expanded={expanded}>
                <div className={`pt-4 ${props.shopping.products.filter(si=>si.type==="MISS").length === 0 ? "hidden" : ""}`}>
                    <div className="text-lg">Chybějící položky:</div>
                    <div className="flex flex-col pt-2">
                        {props.shopping.products.filter(si=>si.type==="MISS").map((item, i)=>{
                            return <MissingItem key={i} data={item as MissingItemDTO} onStateUpdate={()=>{}} showCheckbox={false} onAmountUpdate={_onAmountUpdate}/>
                        })}
                    </div>
                </div>
                <div className={`pt-4 ${props.shopping.products.filter(si=>si.type==="REQ").length === 0 ? "hidden" : ""}`}>
                    <div className="text-lg">Položky:</div>
                    <div className="flex flex-col pt-2">
                        {props.shopping.products.filter(si=>si.type==="REQ").map((item, i)=>{
                            return <ShoppingItem key={i} data={item as ProductShoppingItemDTO} onStateUpdate={()=>{}} showCheckbox={false} onAmountUpdate={_onAmountUpdate}/>
                        })}
                    </div>
                </div>
                <div className={`pt-4 ${props.shopping.products.filter(si=>si.type==="EXTRA").length === 0 ? "hidden" : ""}`}>
                    <div className="text-lg">Extra položky (celkem za {rounded(props.shopping.extraCost,0)} Kč):</div>
                    <div className="flex flex-col pt-2">
                        {props.shopping.products.filter(si=>si.type==="EXTRA").map((item, i)=>{
                            return <ShoppingItem key={i} data={item as ProductShoppingItemDTO} onStateUpdate={()=>{}} showCheckbox={false} onAmountUpdate={_onAmountUpdate}/>
                        })}
                    </div>
                </div>
                <div className={`pt-4 ${props.showSales?"":"hidden"}`}>
                    <div className="text-lg">Zboží v akci:</div>
                    <div className="flex flex-col pt-2">
                        {recommendations
                            .filter((item: SearchItemDTO)=>
                                props.shopping.shopIds.includes((item.bestOffer as OfferDTO).shopId) &&
                                props.shopping.products.find(p=>p.productId === item.id)===undefined)
                            .slice(0,50)
                            .map((item, i)=>{
                                return <SaleRecommendation key={i} 
                                    product={item} 
                                    onAdd={si=>{props.addItemToShopping(si, props.shopping.temporalId as number)}}
                                    shops={shops}/>
                        })}
                    </div>
                </div>
            </Collapsable> 
        </div>
    );
}
//

type SaleRecommendationProps={
    product: SearchItemDTO,
    onAdd: (product: ProductShoppingItemDTO)=>void,
    shops: ShopDTO[]
}
function SaleRecommendation(props: SaleRecommendationProps){
    return (
        <div className="subItem">
            <div className="flex w-full hover:cursor-pointer items-center flex-wrap sm:flex-nowrap gap-y-1">
                <div className="font-bold capitalize flex gap-2 items-center basis-4/5 sm:basis-2/5">
                    <AiFillPlusCircle size="30" onClick={()=>props.onAdd(mapRecommendationToShoppingItem(props.product, props.shops))}/>
                    <div>
                        {props.product.name}
                    </div>
                </div>
                <div className="font-normal text-sm basis-1/5">
                    {unitsToString(props.product.baseAmount, props.product.baseAmountUnit)}
                </div>
                <div className="col-span-2 sm:basis-2/5">
                    <Offer offer={props.product.bestOffer as OfferDTO}/>
                </div>
            </div>
        </div>
    )
}